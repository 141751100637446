import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { LayoutComponent } from './layout.component';
import { RpHeaderComponent } from './rp-header/rp-header.component';
import { RpFooterComponent } from './rp-footer/rp-footer.component';
import { RpSidebarComponent } from './rp-sidebar/rp-sidebar.component';
import { SimplebarAngularModule } from 'simplebar-angular';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';



@NgModule({
  declarations: [
    LayoutComponent,
    RpHeaderComponent,
    RpFooterComponent,
    RpSidebarComponent,
    
  ],
  imports: [
    CommonModule,
    RouterModule,
    SimplebarAngularModule,
    BsDropdownModule.forRoot(),
    ModalModule.forRoot()

  ],
  schemas:[CUSTOM_ELEMENTS_SCHEMA,NO_ERRORS_SCHEMA]
})
export class LayoutsModule { }
