import { Injectable, Inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import {
  map,
  switchMap,
  catchError,
  exhaustMap,
  tap,
  first,
} from 'rxjs/operators';
import { from, of } from 'rxjs';
import { AuthenticationService } from '../../core/services/auth.service';
import {
  login,
  loginSuccess,
  loginFailure,
  logout,
  logoutSuccess,
  Register,
  RegisterSuccess,
  RegisterFailure,
  sendOtp,
  sendOtpSuccess,
  sendOtpFailure,
  logoutFailure,
  getUserProfile,
  getUserProfileSuccess,
  getUserProfileFailure,
  updateUserProfile,
  refreshToken,
  refreshTokenSuccess,
  refreshTokenFailure,
  getUpdatedUserProfileSuccess,
  getNotificationList,
  getNewNotificationFailure,
  getNewNotificationSuccess,
} from './authentication.actions';
import { Router } from '@angular/router';
import { log } from 'console';
import { UserProfile } from '../../shared/modals/common';
import { SharedService } from '../../shared/shared.service';
import { Roles, SweetAlertIcon } from '../../shared/constants/enum';
import { LoaderService } from '../../core/services/loader.service';
import { finalize } from 'rxjs/operators';
import { Validations } from '../../shared/constants/validations';
import { MENU } from '../../layouts/rp-sidebar/menu';

import { RpChangepasswordComponent } from '../../features/users/rp-changepassword/rp-changepassword.component';

@Injectable()
export class AuthenticationEffects {
  ServiceError = Validations.ServiceError;
  // Register$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(Register),
  //     exhaustMap(({ email, username, password }) => {
  //       if (environment.defaultauth === 'fakebackend') {
  //         return this.userService.register({ email, username, password }).pipe(
  //           map((user) => {
  //             this.router.navigate(['/auth/login']);
  //             return RegisterSuccess({ user })
  //           }),
  //           catchError((error) => of(RegisterFailure({ error })))
  //         );
  //       } else {
  //         return this.AuthenticationService.register({ email, username, password }).pipe(
  //           map((user) => {
  //             this.router.navigate(['/auth/login']);
  //             return RegisterSuccess({ user })
  //           })
  //         )
  //       }
  //     })
  //   )
  // );

  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(login),
      exhaustMap(({ userInput, password, deviceId, loginThroughOtp }) => {
        this.loaderService.setLoading(true);
        return this.AuthenticationService.login(
          userInput,
          password,
          deviceId,
          loginThroughOtp
        ).pipe(
          map((response: any) => {
            this.loaderService.setLoading(false);
            if (
              response &&
              response.statusCode === 200 &&
              response.message === 'Login successful.'
            ) {

              this.loaderService.setLoading(false);
              if (response?.data?.hasEmptyPassword) {
                this.loaderService.setLoading(false);
                this.router.navigate(['/auth/newpassword']);
              } else {
                this.loaderService.setLoading(false);
                let user = response.data;
                // debugger;
                const landingPage = this.getLandingPageForRole(user);
                this.router.navigate([landingPage]);
                // this.router.navigate(['/dashboard']);
              }
              console.log(response.data);
              localStorage.setItem(
                'currentUser',
                JSON.stringify(response.data)
              );
              localStorage.setItem('token', response.data.token);
              this.sharedService.updateRole(
                response.data.currentUserRoleDetails.role
              );

              //return '' as any
              return loginSuccess({ user: response.data });
            } else {
              return loginFailure({ error: response });
            }
          }),
          catchError((error) => {
            this.loaderService.setLoading(false);
            console.log(error);
            this.sharedService.showMessageDialog(
              error.error.message,
            '',
              SweetAlertIcon.ERROR
            );
            return of(loginFailure({ error }));
          }),
          finalize(() => this.loaderService.setLoading(false))
        );
      })
    )
  );

  private getLandingPageForRole(user: any): any {
    let role = user.currentUserRoleDetails.role;

    switch (role) {
      case Roles.SUPERADMIN:
        return '/dashboard';
        break;

      case Roles.ADMIN:
        return '/admindashboard';
        break;

      case Roles.CONSUMER:
        return '/consumerdashboard';
        break;

      case Roles.AGENT:
        return '';
        break;

      default:
        console.error('Unknown role');
        return;
    }
  }
  refreshToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(refreshToken),
      exhaustMap((requestPayload) => {
        this.loaderService.setLoading(true);
        return this.AuthenticationService.autoRefreshToken(
          requestPayload.requestPayload
        ).pipe(
          map((response: any) => {
            this.loaderService.setLoading(false);
            if (
              response &&
              response.statusCode === 200 &&
              response.message === 'Token refreshed successfully.'
            ) {
              this.sharedService.updateCurrentUserToken(
                response.data.token,
                response.data.refreshToken,
                response.data.tokenExpiresOn
              );
              localStorage.setItem('token', response.data.token);

              //  this.router.navigate(['/dashboard']);
              return refreshTokenSuccess({ refreshTokenRes: response.data });
            } else {
              return refreshTokenFailure({ refreshTokenerror: response });
            }
          }),
          catchError((error) => {
            this.loaderService.setLoading(false);
            console.log(error);
            this.sharedService.showMessageDialog(
              error.error.message,
              this.ServiceError.Error,
              SweetAlertIcon.ERROR
            );
            return of(loginFailure({ error }));
          }),
          finalize(() => this.loaderService.setLoading(false))
        );
      })
    )
  );

  // sendOtp$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(sendOtp),
  //     exhaustMap(({ userInput }) => {
  //       this.loaderService.setLoading(true);
  //       return this.AuthenticationService.sendOtp(userInput).pipe(
  //         map((data: any) => {
  //           this.loaderService.setLoading(false);
  //           if (data.statusCode == 200) {
  //             return sendOtpSuccess({ sendOtpResponse: data }); // Pass the entire response
  //           } else {
  //             return sendOtpFailure({ sendOtpError: data }); // Pass the entire error response
  //           }
  //         }),
  //         catchError((error) => {
  //           this.loaderService.setLoading(false);
  //           console.log(error);
  //           this.sharedService.showMessageDialog(
  //             error.error.message,
  //             this.ServiceError.Error,
  //             SweetAlertIcon.ERROR
  //           );
  //           return of(sendOtpFailure({ sendOtpError: error }));
  //         }),
  //         finalize(() => this.loaderService.setLoading(false))
  //       );
  //     })
  //   )
  // );


  sendOtp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sendOtp),
      exhaustMap(({ userInput }) => {
        this.loaderService.setLoading(true);
        return this.AuthenticationService.sendOtp(userInput).pipe(
          map((data: any) => {
            this.loaderService.setLoading(false);
            if (data.statusCode === 200) {
              return sendOtpSuccess({ sendOtpResponse: data });
            } else {
              return sendOtpFailure({ sendOtpError: data });
            }
          }),
          catchError((error) => {
            this.loaderService.setLoading(false);
            this.sharedService.showMessageDialog(
              error.error.message,
              this.ServiceError.Error,
              SweetAlertIcon.ERROR
            );
            return of(sendOtpFailure({ sendOtpError: error }));
          }),
          finalize(() => this.loaderService.setLoading(false))
        );
      })
    )
  );

  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(logout),
      exhaustMap(() =>
        this.AuthenticationService.logout().pipe(
          map((data: any) => {
            if (data.statusCode == 200) {
              localStorage.clear();
              this.router.navigate(['/auth/login']);
              return logoutSuccess({ response: data }); // Pass the entire response
            } else {
              return logoutFailure({ error: data }); // Pass the entire error response
            }
          }),
          catchError((error) => {
            this.loaderService.setLoading(false);
            console.log(error);
            this.sharedService.showMessageDialog(
              error.error.message,
              this.ServiceError.Error,
              SweetAlertIcon.ERROR
            );

            return of(logoutFailure({ error }));
          }),
          finalize(() => this.loaderService.setLoading(false))
        )
      )
    )
  );

  userprofile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getUserProfile),
      exhaustMap(() => {
        this.loaderService.setLoading(true);
        return this.AuthenticationService.getUserProfile().pipe(
          map((response: any) => {
            console.log(response);
            this.loaderService.setLoading(false);
            if (response && response.statusCode === 200) {
              return getUserProfileSuccess({ userDetails: response.data });
            } else {
              return getUserProfileFailure({
                getUserProfileFailureError: response,
              });
            }
          }),
          catchError((error) => {
            this.loaderService.setLoading(false);
            console.log(error);
            this.sharedService.showMessageDialog(
              error.error.message,
              this.ServiceError.Error,
              SweetAlertIcon.ERROR
            );
            return of(loginFailure({ error }));
          }),
          finalize(() => this.loaderService.setLoading(false))
        );
      })
    )
  );

  updateuserprofile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateUserProfile),
      exhaustMap((user) => {
        this.loaderService.setLoading(true);
        return this.AuthenticationService.updateUserProfile(user).pipe(
          map((response: any) => {
            console.log(response);
            this.loaderService.setLoading(false);
            if (response && response.statusCode === 200) {
              this.sharedService.showMessageDialog(
                'Success',
                response.message,
                SweetAlertIcon.SUCCESS
              );
              return getUpdatedUserProfileSuccess({
                getUpdatedUserProfileResponse: response,
              });
              // return getUserProfile(); // Dispatch getUserProfile action upon successful update
            } else {
              this.sharedService.showMessageDialog(
                'Failure',
                response.message,
                SweetAlertIcon.WARNING
              );
              return getUserProfileFailure({
                getUserProfileFailureError: response,
              });
            }
          }),
          catchError((error) => {
            console.log(error);
            this.loaderService.setLoading(false);
            this.sharedService.showMessageDialog(
              error.error.message,
              this.ServiceError.Error,
              SweetAlertIcon.ERROR
            );
            return of(
              getUserProfileFailure({ getUserProfileFailureError: error })
            );
          }),
          finalize(() => this.loaderService.setLoading(false))
        );
      })
    )
  );

  getNotificationList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getNotificationList),
      exhaustMap(() => {
        this.loaderService.setLoading(true);
        return this.AuthenticationService.getNotifications().pipe(
          map((data: any) => {
            this.loaderService.setLoading(false);
            if (data.statusCode == 200) {
              return getNewNotificationSuccess({ getNewNotificationResponse: data }); // Pass the entire response
            } else {
              return getNewNotificationFailure({ getNewNotificationError: data }); // Pass the entire error response
            }
          }),
          catchError((error) => {
            this.loaderService.setLoading(false);
            console.log(error);
            this.sharedService.showMessageDialog(
              error.error.message,
              this.ServiceError.Error,
              SweetAlertIcon.ERROR
            );
            return of(getNewNotificationFailure({ getNewNotificationError: error }));
          }),
          finalize(() => this.loaderService.setLoading(false))
        );
      })
    )
  );

  constructor(
    @Inject(Actions) private actions$: Actions,
    private AuthenticationService: AuthenticationService,
    private sharedService: SharedService,
    private router: Router,
    private loaderService: LoaderService
  ) { }
}
