import { Action, createReducer, on } from '@ngrx/store';
import {
    getBillslist, getBilsSuccess, getBillsFailure,
    getManuallist, getManualSuccess, getManualFailure,
    getAdhoclist, getAdhocSuccess, getAdhocFailure,
    resetbillsState, getBillsByIdSuccess, getBillsByIdFailure, createinvildBills, addinvildBillsSuccess, addinvildBillsFailure,
    createBills, addBillsSuccess, addBillsFailure, resetBillReadingsInfoState,
    getResidentialSuccess,
    getresidentialFailure,
    getResidentiallist,
    getBillReadingsInfoList,
    getBillReadingsInfoSuccess,
    getBillReadingsInfoFailure,
    getAdhoclistExport,
    getAdhocExpoertSuccess,
    getAdhocExpoertFailure,
    getManuallistExport,
    getManualExportFailure,
    getManualExportSuccess,
    getBilsExportSuccess,
    getBillsListExport,
    getBillsExportFailure,
    getBillReadingsByMonthList,
    getBillReadingsByMonthSuccess,
    getBillReadingsByMonthFailure,
    getBillReadingsByMonthAdhocList,
    getBillReadingsByMonthAdhocSuccess,
    getBillReadingsByMonthAdhocFailure,
    resetbillsAdhocState,
    resetbillsManualState,
    Paycashbill,
    PaycashbillSuccess,
    PaycashbillFailure,
    getBillsInvoice,
    getBillsInvoiceSuccess,
    getBillsInvoiceFailure,
    resetbillsInvoiceState,
    getBillsInvoiceExport,
    getBillsInvoiceExportSuccess,
    getBillsInvoiceExportFailure,
    resetbillsInvoiceExportState
} from './bills.action';
import { billModel } from './bills.model';

export interface billsState {
    bills: any,
    getBillsResponse: any,
    createinvildBillsResponse: any,
    getManualResponse: any,
    getAdhoctResponse: any,
    getBillsByIdResponse: any,
    createBillsResponse: any,
    getresidentialResponse: any,
    getBillReadingsInfoResponse: any,
    getAdhoctExportResponse: any,
    getManualExportResponse: any,
    getBillsExportResponse: any,
    getBillReadingsByMonthResponse: any,
    getBillReadingsByMonthAdhocResponse: any,
    PaycashbillSuccessResponse: any,
    getBillsInvoiceResponse: any,
    getBillsInvoiceExportResponse: any
}


export const initialState: billsState = {
    bills: null,
    getBillsResponse: null,
    createinvildBillsResponse: null,
    getManualResponse: null,
    getAdhoctResponse: null,
    getBillsByIdResponse: null,
    createBillsResponse: null,
    getresidentialResponse: null,
    getBillReadingsInfoResponse: null,
    getAdhoctExportResponse: null,
    getManualExportResponse: null,
    getBillsExportResponse: null,
    getBillReadingsByMonthResponse: null,
    getBillReadingsByMonthAdhocResponse: null,
    PaycashbillSuccessResponse: null,
    getBillsInvoiceResponse: null,
    getBillsInvoiceExportResponse: null

}

export const Billsreducer = createReducer(
    initialState,
    on(getBillslist, state => ({ ...state })),
    on(getBilsSuccess, (state, { getBillsResponse }) => ({ ...state, getBillsResponse })),
    on(getBillsFailure, (state, { getBillsError }) => ({ ...state, getBillsError })),

    on(getManuallist, state => ({ ...state })),
    on(getManualSuccess, (state, { getManualResponse }) => ({ ...state, getManualResponse })),
    on(getManualFailure, (state, { getManualError }) => ({ ...state, getManualError })),

    on(createinvildBills, state => ({ ...state, error: null })),
    on(addinvildBillsSuccess, (state, { createinvildBillsResponse }) => ({ ...state, createinvildBillsResponse })),
    on(addinvildBillsFailure, (state, { createinvildBillsError }) => ({ ...state, createinvildBillsError })),

    on(Paycashbill, state => ({ ...state, error: null })),
    on(PaycashbillSuccess, (state, { PaycashbillSuccessResponse }) => ({ ...state, PaycashbillSuccessResponse })),
    on(PaycashbillFailure, (state, { PaycashbillFailureError }) => ({ ...state, PaycashbillFailureError })),




    on(createBills, state => ({ ...state, error: null })),
    on(addBillsSuccess, (state, { createBillsResponse }) => ({ ...state, createBillsResponse })),
    on(addBillsFailure, (state, { createBillsError }) => ({ ...state, createBillsError })),

    on(getAdhoclist, state => ({ ...state })),
    on(getAdhocSuccess, (state, { getAdhoctResponse }) => ({ ...state, getAdhoctResponse })),
    on(getAdhocFailure, (state, { getAdhocError }) => ({ ...state, getAdhocError })),


    on(getBillsByIdSuccess, (state, { getBillsByIdResponse }) => ({ ...state, getBillsByIdResponse })),
    on(getBillsByIdFailure, (state, { getBillsByIdError }) => ({ ...state, getBillsByIdError })),

    on(getResidentiallist, state => ({ ...state })),
    on(getResidentialSuccess, (state, { getresidentialResponse }) => ({ ...state, getresidentialResponse })),
    on(getresidentialFailure, (state, { getResidentialError }) => ({ ...state, getResidentialError })),



    on(getBillReadingsInfoList, state => ({ ...state })),
    on(getBillReadingsInfoSuccess, (state, { getBillReadingsInfoResponse }) => ({ ...state, getBillReadingsInfoResponse })),
    on(getBillReadingsInfoFailure, (state, { getBillReadingsInfoError }) => ({ ...state, getBillReadingsInfoError })),


    on(getAdhoclistExport, state => ({ ...state })),
    on(getAdhocExpoertSuccess, (state, { getAdhoctExportResponse }) => ({ ...state, getAdhoctExportResponse })),
    on(getAdhocExpoertFailure, (state, { getAdhocExportError }) => ({ ...state, getAdhocExportError })),


    on(getManuallistExport, state => ({ ...state })),
    on(getManualExportSuccess, (state, { getManualExportResponse }) => ({ ...state, getManualExportResponse })),
    on(getManualExportFailure, (state, { getManualExportError }) => ({ ...state, getManualExportError })),


    on(getBillsListExport, state => ({ ...state })),
    on(getBilsExportSuccess, (state, { getBillsExportResponse }) => ({ ...state, getBillsExportResponse })),
    on(getBillsExportFailure, (state, { getBillsExportError }) => ({ ...state, getBillsExportError })),


    on(getBillReadingsByMonthList, state => ({ ...state })),
    on(getBillReadingsByMonthSuccess, (state, { getBillReadingsByMonthResponse }) => ({ ...state, getBillReadingsByMonthResponse })),
    on(getBillReadingsByMonthFailure, (state, { getBillReadingsByMonthError }) => ({ ...state, getBillReadingsByMonthError })),

    on(getBillReadingsByMonthAdhocList, state => ({ ...state })),
    on(getBillReadingsByMonthAdhocSuccess, (state, { getBillReadingsByMonthAdhocResponse }) => ({ ...state, getBillReadingsByMonthAdhocResponse })),
    on(getBillReadingsByMonthAdhocFailure, (state, { getBillReadingsByMonthAdhocError }) => ({ ...state, getBillReadingsByMonthAdhocError })),


    // on(resetbillsState, () => initialState),


    on(resetBillReadingsInfoState, (state) => ({
        ...state,
        getBillReadingsInfoResponse: null // Resetting this specific property
    })),
    on(resetbillsState, (state) => ({
        ...state,
        getBillsExportResponse: null, getBillsByIdResponse: null
        // Resetting this specific property
    })),

    on(resetbillsManualState, (state) => ({
        ...state,
        getManualExportResponse: null // Resetting this specific property
    })),
    on(resetbillsAdhocState, (state) => ({
        ...state,
        getAdhoctExportResponse: null // Resetting this specific property
    })),

    on(resetbillsInvoiceState, (state) => ({
        ...state,
        getBillsInvoiceResponse: null // Resetting this specific property
    })),
    on(resetbillsInvoiceExportState, (state) => ({
        ...state,
        getBillsInvoiceExportResponse: null // Resetting this specific property
    })),

    //
    on(getBillsInvoice, state => ({ ...state })),
    on(getBillsInvoiceSuccess, (state, { getBillsInvoiceResponse }) => ({ ...state, getBillsInvoiceResponse })),
    on(getBillsInvoiceFailure, (state, { getBillsInvoiceError }) => ({ ...state, getBillsInvoiceError })),

    on(getBillsInvoiceExport, state => ({ ...state })),
    on(getBillsInvoiceExportSuccess, (state, { getBillsInvoiceExportResponse }) => ({ ...state, getBillsInvoiceExportResponse })),
    on(getBillsInvoiceExportFailure, (state, { getBillsInvoiceExportError }) => ({ ...state, getBillsInvoiceExportError })),





);

