import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { User } from '../../store/Authentication/auth.models';
import { AppConfigData } from '../config/appConfigData';
import { isPlatformBrowser } from '@angular/common';
import { Router } from '@angular/router';
import { Faq } from '../../store/Faq/faq.models';
import { Monitoring } from '../../store/Monitoring/monitoring.models';

@Injectable({ providedIn: 'root' })
export class MonitoringService {
  constructor(private http: HttpClient, private router: Router) {}

  getMonitorings(
    projectid: string,
    pageIndex: number,
    pageSize: number | undefined,
    searchTerm: string,
    filter?: string,
    orderBy?: string
  ): Observable<any> {
    let url =
      `${AppConfigData.getMonitoringUrl + projectid}?pageIndex=${pageIndex}` +
      `${pageSize ? `&pageSize=${pageSize}` : ''}` +
      `${pageSize == undefined ? `&criteria=${'All'}` : ''}` +
      `${searchTerm ? `&search=${searchTerm}` : ''}` +
      `${filter ? `&${filter}` : ''}` +
      `${orderBy ? `&${orderBy}` : ''}`;

    return this.http.get<any>(url).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        console.error('getMonitorings failed:', error);
        return throwError(error); // Rethrow the error to propagate it further
      })
    );
  }

  createMonitorings(MonitoringData: Monitoring): Observable<any> {
    return this.http
      .post<any>(AppConfigData.createMonitoringUrl, MonitoringData)
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          console.error('createMonitoring failed:', error);
          return throwError(error); // Rethrow the error to propagate it further
        })
      );
  }

  updateMonitoring(MonitoringData: Monitoring, id: any): Observable<any> {
    return this.http
      .post<any>(AppConfigData.updateMonitoringUrl + id, MonitoringData)
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          console.error('getuserprofile failed:', error);
          return throwError(error); // Rethrow the error to propagate it further
        })
      );
  }

  deleteMonitoring(id: any): Observable<any> {
    return this.http
      .post<any>(AppConfigData.deleteMonitoringUrl + id, null)
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          console.error('deleteFaqs failed:', error);
          return throwError(error); // Rethrow the error to propagate it further
        })
      );
  }
}
