import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { User } from '../../store/Authentication/auth.models';
import { AppConfigData } from '../config/appConfigData';
import { isPlatformBrowser } from '@angular/common';
import { Router } from '@angular/router';
import { Faq } from '../../store/Faq/faq.models';

@Injectable({ providedIn: 'root' })
export class MappingService {
  constructor(private http: HttpClient, private router: Router) {}

  getmappings(
    pageIndex: number = 1,
    pageSize: number = 10,
    searchTerm: string = '',
    id: string,
    filter?: string,
    orderBy?: string
  ): Observable<any> {
    let url;
    url =
      `${AppConfigData.getListOfMappingUmdUrl}?id=${id}&pageSize=${pageSize}` +
      `${pageIndex ? `&pageIndex=${pageIndex}` : ''}` +
      `${searchTerm ? `&search=${searchTerm}` : ''}` +
      `${filter ? `&${filter}` : ''}` +
      `${orderBy ? `&${orderBy}` : ''}`;
    return this.http.get<any>(url).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        console.error('getmappings failed:', error);
        return throwError(error); // Rethrow the error to propagate it further
      })
    );
  }

  // getmappingConsumptions(
  //   pageIndex: number = 1,
  //   pageSize: number = 10,
  //   searchTerm: string = '',
  //   mappingId?: string,
  //   FromDate?: string,
  //   ToDate?: string,
  //   filter?: string,
  //   orderBy?: string
  // ): Observable<any> {
  //   let url =
  //     `${AppConfigData.getListOfMappingUmdUrl}?pageIndex=${pageIndex}&pageSize=${pageSize}` +
  //     `${mappingId ? `&mappingId=${mappingId}` : ''}` +
  //     `${searchTerm ? `&search=${searchTerm}` : ''}` +
  //     `${FromDate ? `&FromDate=${FromDate}` : ''}` +
  //     `${ToDate ? `&ToDate=${ToDate}` : ''}` +
  //     `${filter ? `&${filter}` : ''}` +
  //     `${orderBy ? `&${orderBy}` : ''}`;
  //   return this.http.get<any>(url).pipe(
  //     map((res) => {
  //       return res;
  //     }),
  //     catchError((error) => {
  //       console.error('getmappings failed:', error);
  //       return throwError(error); // Rethrow the error to propagate it further
  //     })
  //   );
  // }

  createmappings(id: string, umdid: string[]): Observable<any> {
    // let payload={
    //   id,umdid.map((id: string) => `${encodeURIComponent(id)}`).
    // }
    return this.http.post<any>(AppConfigData.MappingUmdUrl + id, umdid).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        console.error('createmappings failed:', error);
        return throwError(error); // Rethrow the error to propagate it further
      })
    );
  }

  unmappingStatus(id: string, Umdid: string): Observable<any> {
    // let url = `${AppConfigData.UnMappingUmdUrl + id ? 'umdId' : Umdid}`;
    let url = `${AppConfigData.UnMappingUmdUrl + id}?umdId=${Umdid}`;
    let request = {
      id: id,
      umdId: Umdid,
    };
    return this.http.post<any>(url, null).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        console.error('unmappingStatus failed:', error);
        return throwError(error); // Rethrow the error to propagate it further
      })
    );
  }

  getListOfUnMappingUmdUrl(requst: any): Observable<any> {
    let url = `${AppConfigData.getListOfUnMappingUmdUrl + requst}`;
    return this.http.get<any>(url, requst).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        console.error('getuserprofile failed:', error);
        return throwError(error); // Rethrow the error to propagate it further
      })
    );
  }

  // deletemapping(id: any): Observable<any> {
  //   return this.http.post<any>(AppConfigData.deletemappingsUrl + id, null).pipe(
  //     map((res) => {
  //       return res;
  //     }),
  //     catchError((error) => {
  //       console.error('deleteFaqs failed:', error);
  //       return throwError(error); // Rethrow the error to propagate it further
  //     })
  //   );
  // }
}
