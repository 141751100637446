import {
  HTTP_INTERCEPTORS,
  HttpErrorResponse,
  HttpEvent,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';

import { TokenStorageService } from '../../core/services/token-storage.service';
import { Observable, catchError, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { logout } from '../../store/Authentication/authentication.actions';
import { AuthenticationService } from '../services/auth.service';

const TOKEN_HEADER_KEY = 'Authorization'; // for Node.js Express back-end

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private token: TokenStorageService,
    private router: Router,
    private store: Store,
    private authenticationService: AuthenticationService
  ) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Check if the request has the 'No-Intercept' header

    let authReq = req;
    const token = this.token.getToken();
    const user = this.token.getUser();
    if (token) {
      if (req.headers.has('No-Intercept')) {
        const headers = req.headers.delete('No-Intercept');
        authReq = req.clone({ headers });
      } else {
        const headers = req.headers.set(TOKEN_HEADER_KEY, `Bearer ${token}`);

        if (req.headers.has('projectIdRequired')) {
          authReq = req.clone({
            headers: headers.set('projectId', user?.project?.id || ''),
          });
        } else {
          authReq = req.clone({ headers });
        }
      }
    }

    return next.handle(authReq).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401 && error.statusText === 'Unauthorized') {
          // auto logout if 401 response returned from api
          console.log('error==>', error);
          this.authenticationService.apiError();
        }
        return throwError(error);
      })
    );
  }
}

export const authInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
];
