// Monitorings.effects.ts
import { Injectable, Inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, catchError, exhaustMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Router } from '@angular/router';
import { SharedService } from '../../shared/shared.service';
import { SweetAlertIcon } from '../../shared/constants/enum';
import {
  CreateMonitoringFailure,
  CreateMonitoringSuccess,
  GetAllMonitoring,
  GetMonitoring,
  createMonitoring,
  deleteMonitoring,
  deleteMonitoringFailure,
  deleteMonitoringSuccess,
  getAllMonitoringsFailure,
  getAllMonitoringsSuccess,
  getMonitoringsFailure,
  getMonitoringsSuccess,
  updateMonitoring,
  updateMonitoringFailure,
  updateMonitoringSuccess,
} from './monitoring.actions';
import { LoaderService } from '../../core/services/loader.service';
import { finalize } from 'rxjs/operators';
import { Validations } from '../../shared/constants/validations';
import { MonitoringService } from '../../core/services/monitor.services';
@Injectable()
export class MonitoringsModuleEffects {
  ServiceError = Validations.ServiceError;
  createMonitoring$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createMonitoring),
      exhaustMap((MonitoringData) => {
        console.log(MonitoringData);
        this.loaderService.setLoading(true);
        return this.monitoringService
          .createMonitorings(MonitoringData.MonitoringData)
          .pipe(
            map((response: any) => {
              console.log(response);
              this.loaderService.setLoading(false);
              if (response && response.statusCode === 201) {
                this.sharedService.showMessageDialog(
                  'Success',
                  response.message,
                  SweetAlertIcon.SUCCESS
                );
                return CreateMonitoringSuccess({
                  createMonitoringResponse: response,
                });
              } else {
                this.sharedService.showMessageDialog(
                  'Failure',
                  response.message,
                  SweetAlertIcon.ERROR
                );
                return CreateMonitoringFailure({
                  createMonitoringError: response,
                });
              }
            }),
            catchError((error) => {
              console.log(error);

              this.sharedService.showMessageDialog(
                error.error.message,
                this.ServiceError.Error,
                SweetAlertIcon.ERROR
              );
              return of(
                CreateMonitoringFailure({ createMonitoringError: error })
              );
            }),
            finalize(() => this.loaderService.setLoading(false))
          );
      })
    )
  );

  updateMonitoring$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateMonitoring),
      exhaustMap((MonitoringData, id) => {
        console.log(MonitoringData, '-----', id);
        this.loaderService.setLoading(true);
        return this.monitoringService
          .updateMonitoring(MonitoringData.MonitoringData, MonitoringData.id)
          .pipe(
            map((response: any) => {
              this.loaderService.setLoading(false);
              if (response && response.statusCode === 200) {
                this.sharedService.showMessageDialog(
                  'Success',
                  response.message,
                  SweetAlertIcon.SUCCESS
                );
                return updateMonitoringSuccess({
                  updateMonitoringResponse: response,
                });
                // return GetMonitoring({pageIndex:1,pageSize:10,searchTerm:''})
              } else {
                this.sharedService.showMessageDialog(
                  'Failure',
                  response.message,
                  SweetAlertIcon.ERROR
                );
                return updateMonitoringFailure({
                  updateMonitoringError: response,
                });
              }
            }),
            catchError((error) => {
              console.log(error);
              this.sharedService.showMessageDialog(
                error.error.message,
                this.ServiceError.Error,
                SweetAlertIcon.ERROR
              );
              return of(
                CreateMonitoringFailure({ createMonitoringError: error })
              );
            }),
            finalize(() => this.loaderService.setLoading(false))
          );
      })
    )
  );

  deleteMonitoring$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteMonitoring),
      exhaustMap((MonitoringData, id) => {
        console.log(MonitoringData, '-----', id);
        this.loaderService.setLoading(true);
        return this.monitoringService.deleteMonitoring(MonitoringData.id).pipe(
          map((response: any) => {
            this.loaderService.setLoading(false);
            if (response && response.statusCode === 200) {
              this.sharedService.showMessageDialog(
                'Success',
                response.message,
                SweetAlertIcon.SUCCESS
              );
              return deleteMonitoringSuccess({
                deleteMonitoringResponse: response,
              });
              // return GetMonitoring({pageIndex:1,pageSize:10,searchTerm:''})
            } else {
              this.sharedService.showMessageDialog(
                'Failure',
                response.message,
                SweetAlertIcon.ERROR
              );
              return deleteMonitoringFailure({
                deleteMonitoringError: response,
              });
            }
          }),
          catchError((error) => {
            console.log(error);
            this.sharedService.showMessageDialog(
              error.error.message,
              this.ServiceError.Error,
              SweetAlertIcon.ERROR
            );
            return of(
              deleteMonitoringFailure({ deleteMonitoringError: error })
            );
          }),
          finalize(() => this.loaderService.setLoading(false))
        );
      })
    )
  );

  getMonitoring$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetMonitoring),
      exhaustMap(
        ({ projectid, pageIndex, pageSize, searchTerm, filter, orderBy }) => {
          // if (!searchTerm) {
          this.loaderService.setLoading(true);
          // }
          return this.monitoringService
            .getMonitorings(
              projectid,
              pageIndex,
              pageSize,
              searchTerm,
              filter,
              orderBy
            )
            .pipe(
              map((response: any) => {
                this.loaderService.setLoading(false);
                console.log(response);
                if (response && response.statusCode === 200) {
                  return getMonitoringsSuccess({
                    getMonitoringResponse: response.data,
                  });
                } else {
                  return getMonitoringsFailure({
                    getMonitoringError: response,
                  });
                }
              }),
              catchError((error) => {
                console.log(error);
                this.sharedService.showMessageDialog(
                  error.error.message,
                  this.ServiceError.Error,
                  SweetAlertIcon.ERROR
                );
                return of(getMonitoringsFailure({ getMonitoringError: error }));
              }),
              finalize(() => this.loaderService.setLoading(false))
            );
        }
      )
    )
  );

  getAllMonitoring$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetAllMonitoring),
      exhaustMap(
        ({ projectid, pageIndex, pageSize, searchTerm, filter, orderBy }) => {
          if (!searchTerm) {
            this.loaderService.setLoading(true);
          }
          return this.monitoringService
            .getMonitorings(
              projectid,
              pageIndex,
              pageSize,
              searchTerm,
              filter,
              orderBy
            )
            .pipe(
              map((response: any) => {
                this.loaderService.setLoading(false);
                console.log(response);
                if (response && response.statusCode === 200) {
                  return getAllMonitoringsSuccess({
                    getAllMonitoringResponse: response.data,
                  });
                } else {
                  return getAllMonitoringsFailure({
                    getAllMonitoringError: response,
                  });
                }
              }),
              catchError((error) => {
                console.log(error);
                this.sharedService.showMessageDialog(
                  error.error.message,
                  this.ServiceError.Error,
                  SweetAlertIcon.ERROR
                );
                return of(
                  getAllMonitoringsFailure({ getAllMonitoringError: error })
                );
              }),
              finalize(() => this.loaderService.setLoading(false))
            );
        }
      )
    )
  );

  constructor(
    private actions$: Actions,
    private monitoringService: MonitoringService,
    private sharedService: SharedService,
    private router: Router,
    private loaderService: LoaderService
  ) {}
}
