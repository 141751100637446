<app-rp-loader></app-rp-loader>
<header id="page-topbar">
  <div class="navbar-header">
    <div class="d-flex">
      <!-- LOGO -->
      <div class="navbar-brand-box">

        <a class="logo logo-light" (click)="gotoDashBoard()">
          <span class="logo-sm">
            <img src="../../../assets/images/ripple_logo_mini.png" alt="" height="35">
          </span>
          <span class="logo-lg">
            <img src="../../../assets/images/ripple_full_logo_white.png" alt="" height="50">
          </span>
        </a>
      </div>

      <button type="button" class="btn btn-sm px-3 font-size-16 header-item"
        [ngClass]="{ 'd-none': theme === 'horizontal' }" id="vertical-menu-btn" (click)="toggleMobileMenu($event)">
        <i class="fa fa-fw fa-bars"></i>
      </button>

      <span class="d-flex align-items-center d-lg-none d-sm-block">
        <img src="../../../assets/images/ripple_full_logo_color.png" alt="" height="40">
      </span>

    </div>

    <div class="d-flex">
      <div class="dropdown d-inline-block d-lg-none ms-2" dropdown>
        <button type="button" class="btn header-item noti-icon" id="page-header-search-dropdown" data-toggle="dropdown"
          aria-haspopup="true" dropdownToggle aria-expanded="false">
          <i class="mdi mdi-magnify"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0" *dropdownMenu
          aria-labelledby="page-header-search-dropdown">
          <form class="p-3">
            <div class="m-0">
              <div class="input-group">
                <input type="text" class="form-control" placeholder="Search ..." aria-label="Recipient's username">
                <div class="input-group-append">
                  <button class="btn btn-primary" type="submit"><i class="mdi mdi-magnify"></i></button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      @if((userRole!=listOfRoles.SUPERADMIN)){
      <div class="project-header d-flex align-items-center">
        <img *ngIf="projectDetails?.logoUrl" [src]="projectDetails?.logoUrl" class="me-3 project-logo avatar-xs border"
          alt="Project Logo">
        <h4 class="mb-0 font-size-18">
          {{currentUserData?.project?.name}}
        </h4>
      </div>
      }
      @if(activeInstance ){
      <button class="btn  btn-exit-instance btn-sm d-flex align-items-center m-lg-auto p-2" tooltip="Exit Instance"
        (click)="exitInstance()">
        <i class="mdi mdi-account-convert font-size-14"></i> Back To SuperAdmin
      </button>}
      <!-- @else {
      <h4 class="mb-0 font-size-18" style="margin:25px">{{currentUserData?.project?.name}}</h4>

      } -->
      <!-- <button class="btn btn-light btn-sm align-items-center m-lg-auto" tooltip="Exit Instance" (click)="exitInstance()">
          <i class="fas fa-times"></i> Exit Instance
        </button> -->

      <div class="dropdown d-inline-block" dropdown>
        <button type="button" class="btn header-item noti-icon" id="page-header-notifications-dropdown"
          (click)="openNotificationPanel()" dropdownToggle>
          <i *ngIf="totalNotifications>0" class="bx bx-bell bx-tada"></i>
          <i *ngIf="totalNotifications == 0" class="far fa-bell"></i>
          <span *ngIf="totalNotifications > 0" class="badge bg-danger rounded-pill">
            {{ totalNotifications > 99 ? '99+' : totalNotifications }}
          </span>

        </button>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0 rounded" style="width: 450px;"
          aria-labelledby="page-header-notifications-dropdown" *dropdownMenu>
          <div class="p-3 bg-primary rounded-top" style="color:white">
            <div class="row align-items-center">
              <div class="col">
                <h6 class="m-0">Notifications</h6>
              </div>
              <div class="col-auto" *ngIf="notificationList.length != 0" (click)="openViewAllNotificationPanel()">
                <a href="javascript:void(0);" class="text-white view-all-link">
                  View All
                  <!-- <i class="mdi mdi-arrow-right-circle-outline ms-1"></i> -->
                </a>
              </div>

              <div class="col-auto"  >
                <div class="menu-container" *ngIf="notificationList.length != 0">
                  <!-- Prevent event from closing dropdown -->
                  <i (click)="toggleMenu($event)" class="notificationmenu bx bx-dots-vertical-rounded"></i>

                  <div class="menu" *ngIf="isMenuOpen" style="color:black">
                    <ul>
                      <li (click)="readAllNotifications()"><a><i class='bx bx-check-circle'></i> Mark As All Read</a>
                      </li>
                      <li (click)="clearAllNotifications()"><a><i class='bx bx-trash'></i> Clear All</a></li>
                    </ul>
                  </div>
                </div>

                <!-- <button class="btn btn-sm btn-light" (click)="clearAllNotifications()">Clear All</button> -->
                <i style="color:white" class="mdi mdi-close font-size-16 ms-2 cursor-pointer"
                  (click)="closeNotificationPanel()"></i>
              </div>
            </div>
          </div>
          @if(notificationList.length === 0){ <div class="text-center">
            <!-- Use your SVG icon from assets -->
            <img src="assets/nonotifications.svg" alt="No Notifications" style="width: 170px; height: 170px;" />

            <!-- Display No Notifications message -->
            <p class="mt-2" style="font-size: 1rem; color: #6c757d;">No Notifications</p>
          </div>}


          @else { <div class="header-notification">
            <!-- <ng-container *ngFor="let notification of notificationList | slice:0:4">
              <a href="javascript:void(0);" class="text-reset notification-item" (click)="gotoNotificatoinInfo(notification)">
                <div class="d-flex p-3 mb-2 align-items-center border rounded"
                  [ngClass]="{'bg-light': notification.isRead, 'unreadnotification': !notification.isRead}">
                  <img [src]="getNotificationIcon(notification.type) || notification.userImageUrl || '../../../assets/images/users/avatar-1.jpg'"
                  class="me-3 rounded-circle avatar-xs border" alt="notification-icon">
                  <div class="flex-grow-1">
                    <h6 class="mb-1"
                      [ngClass]="{'text-muted': notification.isRead, 'font-weight-semibold': !notification.isRead}">
                      {{ notification.description }}
                    </h6>
                    <div class="font-size-11 text-muted" style="text-align: right;">
                      <p class="mb-0">
                        <i class="mdi mdi-clock-outline"></i> {{ notification.receivedOn }}
                      </p>
                    </div>
                  </div>
                </div>
              </a>
            </ng-container> -->


            <ng-container *ngFor="let notification of notificationList ">

              <a href="javascript:void(0);" class="text-reset notification-item notifyhover" style="cursor: pointer;"
                (click)="gotoNotificatoinInfo(notification)">
                <div class="d-flex p-3 mb-2 align-items-center border rounded "
                  [ngClass]="{'bg-light': !notification.isRead, 'bg-white': notification.isRead}">

                  <img
                    [src]="getNotificationIcon(notification.type) || notification.userImageUrl || '../../../assets/notifications/default.svg.svg'"
                    class="me-3 rounded-circle avatar-xs " alt="notification-icon">

                  <!-- <i [ngStyle]="{'color': getNotificationColor(notification.type), 'font-size': '30px'}"
                    class="me-3 rounded-circle avatar-xs border {{ getNotificationIconClass(notification.type) }}"></i> -->


                  <div class="flex-grow-1">
                    <h6 class="mb-1"
                      [ngClass]="{'text-muted': notification.isRead, 'font-weight-semibold': !notification.isRead}">
                      {{ notification.description }}
                    </h6>
                    <div class="font-size-11 text-muted" style="text-align: right;">
                      <p class="mb-0">
                        <i class="mdi mdi-clock-outline"></i> {{ notification.receivedOn }}
                      </p>
                    </div>
                  </div>

                  <!-- <div class="ms-2" *ngIf="getRedirectUrl(notification.type)">
                    <i class="mdi mdi-arrow-right-circle-outline" style="font-size: 20px;"></i>
                  </div> -->
                </div>
              </a>
            </ng-container>

          </div>
          }

        </div>
      </div>

      <div class="dropdown d-inline-block" dropdown>
        <button type="button" class="btn header-item" dropdownToggle id="page-header-user-dropdown">
          <img class="rounded-circle header-profile-user "
            [src]="profileData?.fileUrl || 'assets/images/users/user-dummy-img.jpg'" alt="Header Avatar">
          <span class="d-none d-xl-inline-block ms-1">{{profileData?.firstName}} {{profileData?.lastName}} </span>
          <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-end" *dropdownMenu>
          <!-- item-->
          <a class="dropdown-item" data-bs-toggle="modal" data-bs-target=".bs-example-modal-center"
            (click)="openProfileModal()"><i class="bx bx-user font-size-16 align-middle me-1"></i>
            Profile</a>
          <a class="dropdown-item d-block" href="javascript: void(0);" data-bs-toggle="modal"
            data-bs-target=".bs-example-modal-center" (click)="openChangePwdModal()"><i
              class="bx bx-lock font-size-16 align-middle me-1"></i>
            Change password</a>

          @if(userRole==(listOfRoles.ADMIN )|| (userRole==listOfRoles.CONSUMER)){<a class="dropdown-item"
            data-bs-toggle="modal" data-bs-target=".bs-example-modal-center" (click)="openConsumer_FeedbackModal()"><i
              class="bx bx-user font-size-16 align-middle me-1"></i>
            Feedback</a>}
          <div class="dropdown-divider"></div>
          <a class="dropdown-item text-danger" href="javascript: void(0);" (click)="logout()"><i
              class="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i>
            Logout</a>
        </div>
      </div>

      <!-- <div class="dropdown d-inline-block">
          <button type="button" class="btn header-item noti-icon right-bar-toggle" (click)="toggleRightSidebar()">
            <i class="bx bx-cog bx-spin"></i>
          </button>
        </div> -->

    </div>
  </div>
</header>



<!-- Change password modal -->
<div bsModal #centerDataModal="bs-modal" class="modal fade" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header bg-primary text-white">
        <h5 class="modal-title">Change password</h5>
        <button type="button" class="btn-close text-white" data-bs-dismiss="modal" aria-label="Close"
          (click)="centerDataModal?.hide()"></button>
      </div>
      <div class="modal-body">
        <form class="form-horizontal" (ngSubmit)="onSubmit()">
          <div class="mb-3">
            <label>Enter Old Password</label>
            <input type="text" class="form-control" />
          </div>

          <div class="mb-3">
            <label>Enter New Password</label>
            <input type="text" class="form-control" />
          </div>

          <div class="mb-3">
            <label>Re-enter New Password</label>
            <input type="text" class="form-control" />
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <!-- <button type="button" class="btn btn-secondary waves-effect" data-bs-dismiss="modal" >Close</button> -->
        <button type="button" class="btn btn-primary waves-effect waves-light">Save</button>
      </div>
    </div><!-- /.modal -->
  </div>
</div>
<!-- Change password modal End  -->


<!-- Profile modal -->
<div bsModal #profileModal="bs-modal" class="modal fade" tabindex="-1">
  <div class="modal-dialog modal-lg modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header bg-primary text-white">
        <h5 class="modal-title">Profile</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
          (click)="profileModal?.hide()"></button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-sm-12 col-md-4 col-lg-4 text-center">

            <div class="profile-container">
              <label class="hoverable" for="fileInput">
                <img [src]="url ? url : '../../../assets/images/users/avatar-2.jpg'" />
                <div class="background"></div>
              </label>
              <br />
              <input id="fileInput" type="file" [(ngModel)]="url" class="select-profile-picture"
                (change)="onSelectFile($event)" />
              <span class="message text-primary text-decoration-underline font-size-16">Change picture</span>
            </div>


          </div>
          <div class="col-sm-12 col-md-8 col-lg-8">

            <form (ngSubmit)="onSubmit()">
              <div class="row">

                <div class="mb-3 col-lg-6">
                  <label>First Name</label>
                  <input type="text" placeholder="Enter First Name" class="form-control" />

                </div>

                <div class="mb-3 col-lg-6">
                  <label>Last Name</label>
                  <input type="text" placeholder="Enter Last Name" class="form-control" />

                </div>

                <div class="mb-3 col-lg-6">
                  <label>Email Address</label>
                  <input type="text" placeholder="Enter Email Address" class="form-control" />

                </div>
                <div class="mb-3 col-lg-6">
                  <label>Mobile Number</label>
                  <input type="text" placeholder="Enter Mobile Number" class="form-control" />

                </div>
                <div class="mb-3 col-lg-6">
                  <label>Second Mobile Number</label>
                  <input type="text" placeholder="Enter Second Mobile Number" class="form-control" />

                </div>
              </div>
            </form>

          </div>
        </div>

      </div>
      <div class="modal-footer">
        <!-- <button type="button" class="btn btn-secondary waves-effect" data-bs-dismiss="modal" >Close</button> -->
        <button type="button" class="btn btn-primary waves-effect waves-light">Save</button>
      </div>
    </div><!-- /.modal -->
  </div>
</div>
<!-- Profile modal End  -->



<div class="right-side-panel" [class.open]="isOpen">

  <!-- Header with Clear All button -->
  <div class="d-flex justify-content-between py-2 px-3 align-items-center bg-primary border-bottom">
    <h5 class="mb-0 text-white">Notifications</h5>
    <div>
      <div class="menu-container">
        <i (click)="toggleMenu($event)" class="notificationmenu bx bx-dots-vertical-rounded"></i>

        <div class="menu" *ngIf="isMenuOpen">
          <ul>
            <li (click)="readAllNotifications()"><a><i class='bx bx-check-circle'></i> Mark As All Read</a>
            </li>
            <li (click)="clearAllNotifications()"><a><i class='bx bx-trash'></i> Clear All</a></li>
          </ul>
        </div>
      </div>

      <!-- <button class="btn btn-sm btn-light" (click)="clearAllNotifications()">Clear All</button> -->
      <i style="color:white" class="mdi mdi-close font-size-16 ms-2 cursor-pointer"
        (click)="closeNotificationPanel()"></i>
    </div>
  </div>


  @if(notificationList.length === 0){ <div class="text-center" style="margin-top: 50%;">
    <!-- Use your SVG icon from assets -->
    <img src="assets/nonotifications.svg" alt="No Notifications" style="width: 170px; height: 170px;" />

    <!-- Display No Notifications message -->
    <p class="mt-2" style="font-size: 1rem; color: #6c757d;">No Notifications</p>
  </div>}


  @else {<div class="content">
    <div class="mt-2">
      <ng-container *ngFor="let notification of notificationList">
        <a href="javascript:void(0);" class="text-reset notification-item" (click)="gotoNotificatoinInfo(notification)">
          <div class="d-flex p-3 mb-2 align-items-center border"
            [ngClass]="{'bg-white': notification.isRead, 'unreadnotification': !notification.isRead}">
            <img
              [src]="getNotificationIcon(notification.type) || notification.userImageUrl || '../../../assets/notifications/default.svg'"
              class="me-3 rounded-circle avatar-xs " alt="notification-icon">
            <!-- <i [ngStyle]="{'color': getNotificationColor(notification.type), 'font-size': '30px'}"
              class="me-3 rounded-circle avatar-xs border {{ getNotificationIconClass(notification.type) }}"></i> -->
            <div class="flex-grow-1">
              <h6 class="mb-1"
                [ngClass]="{'text-muted': notification.isRead, 'font-weight-semibold': !notification.isRead}">
                {{ notification.description }}
              </h6>
              <div class="font-size-11 text-muted" style="text-align: right;">
                <p class="mb-0">
                  <i class="mdi mdi-clock-outline"></i> {{ notification.receivedOn }}
                </p>
              </div>
            </div>
            <!-- <div class="ms-2" *ngIf="getRedirectUrl(notification.type)">
              <i class="mdi mdi-arrow-right-circle-outline" style="font-size: 20px;"></i>
            </div> -->
            <!-- Display icon if notification is unread -->
            <!-- <div class="ms-2">
              <i *ngIf="!notification.isRead" class="mdi mdi-email-alert-outline"></i>
              <i *ngIf="notification.isRead" class="mdi mdi-email-open-outline"></i>
            </div> -->
          </div>
        </a>
      </ng-container>
    </div>
  </div>

  }
</div>