import { Injectable } from '@angular/core';
import { userListModel } from '../../../app/store/UserList/userlist.model';
import { Router } from '@angular/router';

import { HttpClient } from '@angular/common/http';

import { catchError, map } from 'rxjs/operators';
import { AppConfigData } from '../config/appConfigData';
import { BehaviorSubject, Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private http: HttpClient, private router: Router) { }

  getUsers(
    pageIndex: number = 1,
    pageSize: number | undefined,
    searchTerm: string = '',
    filter?: string,
    orderBy?: string
  ): Observable<any> {
    let url =
      `${AppConfigData.getUsersUrl}?pageIndex=${pageIndex}` +
      `${pageSize ? `&pageSize=${pageSize}` : ''}` +
      `${searchTerm ? `&search=${searchTerm}` : ''}` +
      `${filter ? `&${filter}` : ''}` +
      `${pageSize == undefined ? `&criteria=${'All'}` : ''}` +
      `${orderBy ? `&${orderBy}` : ''}`;
    return this.http.get<any>(url).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        console.error('getMasters failed:', error);
        return throwError(error); // Rethrow the error to propagate it further
      })
    );
  }

  UserupdateStatusUrl(userId: any, meterStatus: any): Observable<any> {
    //'userData->', userId, meterStatus);
    return this.http
      .post<any>(
        AppConfigData.userStatusUrl + userId + `?status=${meterStatus} `,
        null
      )
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          console.error('Update Meter Status  failed:', error);
          return throwError(error); // Rethrow the error to propagate it further
        })
      );
  }
  //Create  User
  createUsers(userData: any): Observable<any> {
    console.log('userData->', userData);
    return this.http.post<any>(AppConfigData.createUsersUrl, userData).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        console.error('getuserprofile failed:', error);
        return throwError(error); // Rethrow the error to propagate it further
      })
    );
  }
  //get Uset By Id
  getUserById(id: any): Observable<any> {
    return this.http.get<any>(AppConfigData.getUserByIdUrl + id).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        console.error('getuserprofile failed:', error);
        return throwError(error); // Rethrow the error to propagate it further
      })
    );
  }

  updateUser(userData: any, id: any): Observable<any> {
    return this.http
      .post<any>(AppConfigData.updateusersUrl + id, userData)
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          console.error('getuserprofile failed:', error);
          return throwError(error); // Rethrow the error to propagate it further
        })
      );
  }

  deleteUser(id: any): Observable<any> {
    return this.http.post<any>(AppConfigData.deleteUsersUrl + id, null).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        console.error('deleteFaqs failed:', error);
        return throwError(error); // Rethrow the error to propagate it further
      })
    );
  }
}
