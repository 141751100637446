import { Action, createReducer, on } from '@ngrx/store';
import {
  getWalletlist,
  getWalletSuccess,
  getWalletFailure,
  getWalletHistorylist,
  getWalletHistoryFailure,
  getWalletHistorySuccess,
  getRechargeHistorylist,
  getRechargeHistorySuccess,
  getRechargeHistoryFailure,
  getwalletHistorylist,
  getwalletHistorylistSuccess,
  getwalletHistorylistFailure,
  getAllWalletSuccess,
  getAllWalletHistorySuccess,
  getAllRechargeHistorySuccess,
  resetState,
  getwalletPyByCashSuccess,
  getwalletPyByCash,
  getwalletPyByCashFilure,
  resetHistoryState,

} from './wallet.action';

export interface WalletState {
  wallet: any;
  getWalletResponse: any;
  getWalletHistoryResponse: any;
  getRechargeHistoryResponse: any;
  getwalletError: any; // Add this line to track error state
  getwalletHistoryError: any; // Add this line to track error state
  getRechargeHistoryError: any; // Add this line to track error state
  walletHistroeyResponse: any;
  getAllWalletResponse: any;
  getAllWalletHistoryResponse: any;
  getAllRechargeHistoryResponse: any;
  walletPyByCashResponse: any;
}

export const initialState: WalletState = {
  wallet: null,
  getWalletResponse: null,
  getWalletHistoryResponse: null,
  getRechargeHistoryResponse: null,
  getwalletError: null, // Initialize error state
  getwalletHistoryError: null, // Initialize error state
  getRechargeHistoryError: null, // Initialize error state
  walletHistroeyResponse: null,
  getAllWalletResponse: null,
  getAllWalletHistoryResponse: null,
  getAllRechargeHistoryResponse: null,
  walletPyByCashResponse: null
};

export const WalletReducer = createReducer(
  initialState,
  on(getWalletlist, (state) => ({ ...state })),
  on(getWalletSuccess, (state, { getWalletResponse }) => ({
    ...state,
    getWalletResponse,
  })),
  on(getWalletFailure, (state, { getwalletError }) => ({
    ...state,
    getwalletError,
  })),


  on(getwalletPyByCash, (state) => ({ ...state })),
  on(getwalletPyByCashSuccess, (state, { walletPyByCashResponse }) => ({
    ...state,
    walletPyByCashResponse,
  })),
  on(getwalletPyByCashFilure, (state, { walletPyByCashfailure }) => ({
    ...state,
    walletPyByCashfailure,
  })),


  on(getWalletHistorylist, (state) => ({ ...state })),
  on(getWalletHistorySuccess, (state, { getWalletHistoryResponse }) => ({
    ...state,
    getWalletHistoryResponse,
  })),
  on(getWalletHistoryFailure, (state, { getwalletHistoryError }) => ({
    ...state,
    getwalletHistoryError,
  })),

  on(getRechargeHistorylist, (state) => ({ ...state })),
  on(getRechargeHistorySuccess, (state, { getRechargeHistoryResponse }) => ({
    ...state,
    getRechargeHistoryResponse,
  })),
  on(getRechargeHistoryFailure, (state, { getRechargeHistoryError }) => ({
    ...state,
    getRechargeHistoryError,
  })),

  on(getwalletHistorylist, (state) => ({ ...state })),
  on(getwalletHistorylistSuccess, (state, { walletHistroeyResponse }) => ({
    ...state,
    walletHistroeyResponse,
  })),
  on(getwalletHistorylistFailure, (state, { walletHistroeyfailure }) => ({
    ...state,
    walletHistroeyfailure,
  })),

  on(getAllWalletSuccess, (state, { getAllWalletResponse }) => ({
    ...state,
    getAllWalletResponse,
  })),

  on(getAllWalletHistorySuccess, (state, { getAllWalletHistoryResponse }) => ({
    ...state,
    getAllWalletHistoryResponse,
  })),
  on(
    getAllRechargeHistorySuccess,
    (state, { getAllRechargeHistoryResponse }) => ({
      ...state,
      getAllRechargeHistoryResponse,
    })
  ),

  on(resetState, (state) => ({
    ...state,
    getAllWalletResponse: null, // Resetting this specific property
    getAllWalletHistoryResponse: null,
    getAllRechargeHistoryResponse: null,
    getWalletHistoryResponse: null,
    walletPyByCashResponse: null


  })),
  on(resetHistoryState, (state) => ({
    ...state, getWalletHistoryResponse: null


  })),


  //


);


