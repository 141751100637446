import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { Router } from '@angular/router';
import { SharedService } from '../../shared/shared.service';
import { map, catchError, exhaustMap } from 'rxjs/operators';
import { LoaderService } from '../../core/services/loader.service';
import { finalize } from 'rxjs/operators';
import { Validations } from '../../shared/constants/validations';
import { SweetAlertIcon } from '../../shared/constants/enum';
import { BillSettingsService } from '../../core/services/bill-settings.service';
import {
  addBillChargeFailure,
  addBillChargeSuccess,
  addInvoiceFailure,
  addInvoiceSuccess,
  addOptionalBillChargeFailure,
  addOptionalBillChargeSuccess,
  createBillCharge,
  createInvoice,
  createOptionalBillCharge,
  deleteBillCharge,
  deleteBillChargeFailure,
  deleteBillChargeSuccess,
  deleteInvoice,
  deleteInvoiceFailure,
  deleteInvoiceSuccess,
  getAllBilSettingSuccess,
  getAllBilinvloiceuccess,
  getAllBillSettingFailure,
  getAllBillSettingslists,
  getAllBillinvloiceFailure,
  getAllBillinvloicelist1,
  getBilSettingSuccess,
  getBilinvloiceuccess,
  getBillSettingFailure,
  getBillSettingslists,
  getBillinvloiceFailure,
  getBillinvloicelist1,
  getOptionalFailure,
  getOptionalSuccess,
  getOptionallist,
  updateBillCharge,
  updateBillChargeFailure,
  updateBillChargeSuccess,
  updateInvoice,
  updateInvoiceFailure,
  updateInvoiceSuccess,
  updateOptionalBillCharge,
  updateOptionalBillChargeFailure,
  updateOptionalBillChargeSuccess,
} from './billSettings.action';

@Injectable()
export class billSettingsEffects {
  ServiceError = Validations.ServiceError;

  getBillSettingslists$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getBillSettingslists),
      exhaustMap(
        ({ pageIndex, pageSize, searchTerm, projectId, filter, orderBy }) => {
          // if (!searchTerm) {
          this.loaderService.setLoading(true);
          // }
          return this.BillsService.getBillsSetingUrl(
            pageIndex,
            pageSize,
            searchTerm,
            projectId,
            filter,
            orderBy
          ).pipe(
            map((response: any) => {
              this.loaderService.setLoading(false);
              console.log(response);
              if (response && response.statusCode === 200) {
                return getBilSettingSuccess({
                  getBillSettingResponse: response.data,
                });
              } else {
                return getBillSettingFailure({ getBillSettingError: response });
              }
            }),
            catchError((error) => {
              console.log(error);
              this.sharedService.showMessageDialog(
                error.error.message,
                this.ServiceError.Error,
                SweetAlertIcon.ERROR
              );
              return of(getBillSettingFailure({ getBillSettingError: error }));
            }),
            finalize(() => this.loaderService.setLoading(false))
          );
        }
      )
    )
  );

  getAllBillSettingslists$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAllBillSettingslists),
      exhaustMap(
        ({ pageIndex, pageSize, searchTerm, projectId, filter, orderBy }) => {
          // if (!searchTerm) {
          this.loaderService.setLoading(true);
          // }
          return this.BillsService.getBillsSetingUrl(
            pageIndex,
            pageSize,
            searchTerm,
            projectId,
            filter,
            orderBy
          ).pipe(
            map((response: any) => {
              this.loaderService.setLoading(false);
              console.log(response);
              if (response && response.statusCode === 200) {
                return getAllBilSettingSuccess({
                  getAllBillSettingResponse: response.data,
                });
              } else {
                return getAllBillSettingFailure({
                  getAllBillSettingError: response,
                });
              }
            }),
            catchError((error) => {
              console.log(error);
              this.sharedService.showMessageDialog(
                error.error.message,
                this.ServiceError.Error,
                SweetAlertIcon.ERROR
              );
              return of(
                getAllBillSettingFailure({ getAllBillSettingError: error })
              );
            }),
            finalize(() => this.loaderService.setLoading(false))
          );
        }
      )
    )
  );

  getBillinvloicelist1$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getBillinvloicelist1),
      exhaustMap(({ pageIndex, pageSize, searchTerm, orderBy, filter }) => {
        // if (!searchTerm) {
        this.loaderService.setLoading(true);
        // }
        return this.BillsService.getInvoiceUrl(
          pageIndex,
          pageSize,
          searchTerm,
          orderBy,
          filter
        ).pipe(
          map((response: any) => {
            this.loaderService.setLoading(false);
            console.log(response);
            if (response && response.statusCode === 200) {
              return getBilinvloiceuccess({
                getBillinvloiceResponse: response.data,
              });
            } else {
              return getBillinvloiceFailure({
                getBillinvloiceError: response,
              });
            }
          }),
          catchError((error) => {
            console.log(error);
            this.sharedService.showMessageDialog(
              error.error.message,
              this.ServiceError.Error,
              SweetAlertIcon.ERROR
            );
            return of(getBillinvloiceFailure({ getBillinvloiceError: error }));
          }),
          finalize(() => this.loaderService.setLoading(false))
        );
      })
    )
  );

  getAllBillinvloicelist1$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAllBillinvloicelist1),
      exhaustMap(
        ({ pageIndex, pageSize, searchTerm, projectId, filter, orderBy }) => {
          // if (!searchTerm) {
          this.loaderService.setLoading(true);
          // }
          return this.BillsService.getInvoiceUrl(
            pageIndex,
            pageSize,
            searchTerm,
            filter
          ).pipe(
            map((response: any) => {
              this.loaderService.setLoading(false);
              console.log(response);
              if (response && response.statusCode === 200) {
                return getAllBilinvloiceuccess({
                  getAllBillinvloiceResponse: response.data,
                });
              } else {
                return getAllBillinvloiceFailure({
                  getAllBillinvloiceError: response,
                });
              }
            }),
            catchError((error) => {
              console.log(error);
              this.sharedService.showMessageDialog(
                error.error.message,
                this.ServiceError.Error,
                SweetAlertIcon.ERROR
              );
              return of(
                getAllBillinvloiceFailure({ getAllBillinvloiceError: error })
              );
            }),
            finalize(() => this.loaderService.setLoading(false))
          );
        }
      )
    )
  );
  //optinal fetch
  getOptionallist$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getOptionallist),
      exhaustMap(({ projectId }) => {
        return this.BillsService.getOptionalBillChargeUrl(projectId).pipe(
          map((response: any) => {
            this.loaderService.setLoading(false);
            console.log(response);
            if (response && response.statusCode === 200) {
              return getOptionalSuccess({ getOptionalResponse: response.data });
            } else {
              return getOptionalFailure({ getOptionalError: response });
            }
          }),
          catchError((error) => {
            console.log(error);
            this.sharedService.showMessageDialog(
              error.error.message,
              this.ServiceError.Error,
              SweetAlertIcon.ERROR
            );
            return of(getOptionalFailure({ getOptionalError: error }));
          }),
          finalize(() => this.loaderService.setLoading(false))
        );
      })
    )
  );

  //createBillCharge

  createBillCharge$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createBillCharge),
      exhaustMap((billCharge) => {
        console.log(billCharge);
        this.loaderService.setLoading(true);
        return this.BillsService.createBillCharge(billCharge.billCharge).pipe(
          map((response: any) => {
            console.log(response);
            this.loaderService.setLoading(false);
            if (response && response.statusCode === 201) {
              this.sharedService.showMessageDialog(
                'Success',
                response.message,
                SweetAlertIcon.SUCCESS
              );
              return addBillChargeSuccess({
                createBillChargeResponse: response,
              });
            } else {
              this.sharedService.showMessageDialog(
                'Failure',
                response.message,
                SweetAlertIcon.ERROR
              );
              return addBillChargeFailure({ createBillChargeError: response });
            }
          }),
          catchError((error) => {
            console.log(error);

            this.sharedService.showMessageDialog(
              error.error.message,
              this.ServiceError.Error,
              SweetAlertIcon.ERROR
            );
            return of(addBillChargeFailure({ createBillChargeError: error }));
          }),
          finalize(() => this.loaderService.setLoading(false))
        );
      })
    )
  );

  // Create invoice
  createInvoice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createInvoice),
      exhaustMap((Invoice, projectId) => {
        console.log(Invoice);
        this.loaderService.setLoading(true);
        return this.BillsService.createInvoiceAddUrl(
          Invoice.Invoice,
          Invoice.projectId
        ).pipe(
          map((response: any) => {
            console.log(response);
            // this.loaderService.setLoading(false);
            if (response && response.statusCode === 201) {
              this.sharedService.showMessageDialog(
                'Success',
                response.message,
                SweetAlertIcon.SUCCESS
              );
              return addInvoiceSuccess({ createInvoiceResponse: response });
            } else {
              this.sharedService.showMessageDialog(
                'Failure',
                response.message,
                SweetAlertIcon.ERROR
              );
              return addInvoiceFailure({ createInvoiceError: response });
            }
          }),
          catchError((error) => {
            console.log(error);
            this.loaderService.setLoading(false);
            this.sharedService.showMessageDialog(
              error.error.message,
              this.ServiceError.Error,
              SweetAlertIcon.ERROR
            );
            return of(addInvoiceFailure({ createInvoiceError: error }));
          }),
          finalize(() => this.loaderService.setLoading(false))
        );
      })
    )
  );

  createOptionalBillCharge$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createOptionalBillCharge),
      exhaustMap((OptionalbillCharge) => {
        console.log(OptionalbillCharge);
        this.loaderService.setLoading(true);
        return this.BillsService.createoptionalBillChargeUrl(
          OptionalbillCharge.OptionalbillCharge
        ).pipe(
          map((response: any) => {
            console.log(response);
            this.loaderService.setLoading(false);
            if (response && response.statusCode === 201) {
              this.sharedService.showMessageDialog(
                'Success',
                response.message,
                SweetAlertIcon.SUCCESS
              );
              return addOptionalBillChargeSuccess({
                createOptionalBillChargeResponse: response,
              });
            } else {
              this.sharedService.showMessageDialog(
                'Failure',
                response.message,
                SweetAlertIcon.ERROR
              );
              return addOptionalBillChargeFailure({
                createOptionalBillChargeError: response,
              });
            }
          }),
          catchError((error) => {
            console.log(error);

            this.sharedService.showMessageDialog(
              error.error.message,
              this.ServiceError.Error,
              SweetAlertIcon.ERROR
            );
            return of(
              addOptionalBillChargeFailure({
                createOptionalBillChargeError: error,
              })
            );
          }),
          finalize(() => this.loaderService.setLoading(false))
        );
      })
    )
  );

  updateBillCharge$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateBillCharge),
      exhaustMap((UpdateBillCharge, id) => {
        console.log(UpdateBillCharge, '-----', id);
        this.loaderService.setLoading(true);
        return this.BillsService.updatetBillCharge(
          UpdateBillCharge.UpdateBillCharge,
          UpdateBillCharge.id
        ).pipe(
          map((response: any) => {
            this.loaderService.setLoading(false);
            if (response && response.statusCode === 200) {
              this.sharedService.showMessageDialog(
                'Success',
                response.message,
                SweetAlertIcon.SUCCESS
              );
              return updateBillChargeSuccess({ updateBillChargeResponse: response, });

            } else {
              this.sharedService.showMessageDialog(
                'Failure',
                response.message,
                SweetAlertIcon.ERROR
              );
              return updateBillChargeFailure({
                updateBillChargeError: response,
              });
            }
          }),
          catchError((error) => {
            console.log(error);
            this.sharedService.showMessageDialog(
              error.error.message,
              this.ServiceError.Error,
              SweetAlertIcon.ERROR
            );
            return of(
              updateBillChargeFailure({ updateBillChargeError: error })
            );
          }),
          finalize(() => this.loaderService.setLoading(false))
        );
      })
    )
  );

  updateInvoice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateInvoice),
      exhaustMap((UpdateInvoice, id) => {
        console.log(UpdateInvoice, '-----', id);
        this.loaderService.setLoading(true);
        return this.BillsService.updateinvoiceAddUrl(
          UpdateInvoice.UpdateInvoice,
          UpdateInvoice.id,
          UpdateInvoice.projectId
        ).pipe(
          map((response: any) => {
            // this.loaderService.setLoading(false);
            if (response && response.statusCode === 200) {
              this.sharedService.showMessageDialog(
                'Success',
                response.message,
                SweetAlertIcon.SUCCESS
              );
              return updateInvoiceSuccess({ updateInvoiceResponse: response });
            } else {
              this.sharedService.showMessageDialog(
                'Failure',
                response.message,
                SweetAlertIcon.ERROR
              );
              return updateInvoiceFailure({ updateInvoiceError: response });
            }
          }),
          catchError((error) => {
            console.log(error);
            this.loaderService.setLoading(false);
            this.sharedService.showMessageDialog(
              error.error.message,
              this.ServiceError.Error,
              SweetAlertIcon.ERROR
            );
            return of(updateInvoiceFailure({ updateInvoiceError: error }));
          }),
          finalize(() => this.loaderService.setLoading(false))
        );
      })
    )
  );

  updateOptionalBillCharge$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateOptionalBillCharge),
      exhaustMap((UpdateOptionalBillCharge, id) => {
        console.log(UpdateOptionalBillCharge, '-----', id);
        this.loaderService.setLoading(true);
        return this.BillsService.updateoptionalBillChargeUrl(
          UpdateOptionalBillCharge.UpdateOptionalBillCharge
        ).pipe(
          map((response: any) => {
            this.loaderService.setLoading(false);
            if (response && response.statusCode === 200) {
              this.sharedService.showMessageDialog(
                'Success',
                response.message,
                SweetAlertIcon.SUCCESS
              );
              return updateOptionalBillChargeSuccess({
                updateOptionalBillChargeResponse: response,
              });
            } else {
              this.sharedService.showMessageDialog(
                'Failure',
                response.message,
                SweetAlertIcon.ERROR
              );
              return updateOptionalBillChargeFailure({
                updateOptionalBillChargeError: response,
              });
            }
          }),
          catchError((error) => {
            console.log(error);
            this.sharedService.showMessageDialog(
              error.error.message,
              this.ServiceError.Error,
              SweetAlertIcon.ERROR
            );
            return of(
              updateOptionalBillChargeFailure({
                updateOptionalBillChargeError: error,
              })
            );
          }),
          finalize(() => this.loaderService.setLoading(false))
        );
      })
    )
  );

  deleteBillCharge$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteBillCharge),
      exhaustMap((id) => {
        console.log(id, '-----');
        this.loaderService.setLoading(true);
        return this.BillsService.deleteBillCharge(id.id).pipe(
          map((response: any) => {
            this.loaderService.setLoading(false);
            if (response && response.statusCode === 200) {
              this.sharedService.showMessageDialog(
                'Success',
                response.message,
                SweetAlertIcon.SUCCESS
              );
              return deleteBillChargeSuccess({
                deleteBillChargeResponse: response,
              });
              //return getConsumer({ ProjectId: userData.projectId, pageIndex: 1, pageSize: 10, searchTerm: '' })
            } else {
              this.sharedService.showMessageDialog(
                'Failure',
                response.message,
                SweetAlertIcon.ERROR
              );
              return deleteBillChargeFailure({
                deleteBillChargeError: response,
              });
            }
          }),
          catchError((error) => {
            console.log(error);
            this.sharedService.showMessageDialog(
              error.error.message,
              this.ServiceError.Error,
              SweetAlertIcon.ERROR
            );
            return of(
              deleteBillChargeFailure({ deleteBillChargeError: error })
            );
          }),
          finalize(() => this.loaderService.setLoading(false))
        );
      })
    )
  );

  deleteInvoice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteInvoice),
      exhaustMap((id) => {
        console.log(id, '-----');
        this.loaderService.setLoading(true);
        return this.BillsService.deleteInvoicead(id.id).pipe(
          map((response: any) => {
            this.loaderService.setLoading(false);
            if (response && response.statusCode === 200) {
              this.sharedService.showMessageDialog(
                'Success',
                response.message,
                SweetAlertIcon.SUCCESS
              );
              return deleteInvoiceSuccess({ deleteInvoiceResponse: response });
              //return getConsumer({ ProjectId: userData.projectId, pageIndex: 1, pageSize: 10, searchTerm: '' })
            } else {
              this.sharedService.showMessageDialog(
                'Failure',
                response.message,
                SweetAlertIcon.ERROR
              );
              return deleteInvoiceFailure({ deleteInvoiceError: response });
            }
          }),
          catchError((error) => {
            console.log(error);
            this.sharedService.showMessageDialog(
              error.error.message,
              this.ServiceError.Error,
              SweetAlertIcon.ERROR
            );
            return of(deleteInvoiceFailure({ deleteInvoiceError: error }));
          }),
          finalize(() => this.loaderService.setLoading(false))
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private router: Router,
    private BillsService: BillSettingsService,
    private loaderService: LoaderService,
    private sharedService: SharedService
  ) { }
}
