
<div id="layout-wrapper">

    <app-rp-header (mobileMenuButtonClicked)="onToggleMobileMenu()"> </app-rp-header>
  
    <app-rp-sidebar (mobileMenuButtonClicked)="onToggleMobileMenuClosed()"></app-rp-sidebar>
  
    <div class="main-content">
      <div class="page-content">
        <!-- content -->
        <router-outlet></router-outlet>
      </div>
  
      <!-- footer -->
      <app-rp-footer></app-rp-footer>
    </div>
  </div>
  