import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AppConfigData } from '../config/appConfigData';
import { Router } from '@angular/router';
import { Master } from '../../store/Masters/masters.models';
import { Supplier } from '../../store/Suppliers/suppliers.models';

@Injectable({ providedIn: 'root' })
export class SuppliersService {
  constructor(private http: HttpClient, private router: Router) {}

  getSuppliers(
    pageIndex: number = 1,
    pageSize: number | undefined,
    searchTerm: string = '',
    filter?: string,
    orderBy?: string
  ): Observable<any> {
    let url;
    url =
      `${AppConfigData.getSuppliersUrl}?pageIndex=${pageIndex}` +
      `${pageSize ? `&pageSize=${pageSize}` : ''}` +
      `${searchTerm ? `&search=${searchTerm}` : ''}` +
      `${pageSize == undefined ? `&criteria=${'All'}` : ''}` +
      `${filter ? `&${filter}` : ''}` +
      `${orderBy ? `&${orderBy}` : ''}`;
    // if (searchTerm) {
    //   url = `${AppConfigData.getSuppliersUrl}?pageIndex=${pageIndex}&pageSize=${pageSize}&search=${searchTerm}`;
    // } else {
    //   url = `${AppConfigData.getSuppliersUrl}?pageIndex=${pageIndex}&pageSize=${pageSize}`;
    // }
    return this.http.get<any>(url).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        console.error('getSuppliers:', error);
        return throwError(error); // Rethrow the error to propagate it further
      })
    );
  }

  createSupplier(supplierData: any): Observable<any> {
    let request = {
      masterId: supplierData.masterId,
      name: supplierData.name,
    };
    return this.http.post<any>(AppConfigData.createSuppliersUrl, request).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        console.error('createSupplier:', error);
        return throwError(error); // Rethrow the error to propagate it further
      })
    );
  }

  updateSupplier(supplierData: Supplier, id: any): Observable<any> {
    let request = {
      masterId: supplierData.masterId,
      name: supplierData.name,
    };

    return this.http
      .post<any>(AppConfigData.updateSuppliersUrl + id, request)
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          console.error('updatesuppliers', error);
          return throwError(error); // Rethrow the error to propagate it further
        })
      );
  }

  deleteSupplier(id: any): Observable<any> {
    return this.http
      .post<any>(AppConfigData.deleteSuppliersUrl + id, null)
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          console.error('deleteSupplier:', error);
          return throwError(error); // Rethrow the error to propagate it further
        })
      );
  }
}
