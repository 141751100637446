import { Component, OnInit } from '@angular/core';
import { MessagingService } from './core/services/message.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  title = 'Ripple';
  message: any;

  constructor(private messagingService: MessagingService) { }
  ngOnInit(): void {
    // this.messagingService.requestPermission();
    this.messagingService.checkNotificationPermission();
    this.messagingService.receiveMessage();
    this.message = this.messagingService.currentMessage;
    /// console.log(this.message);
    //window.addEventListener('beforeunload', this.handleBeforeUnload);
  }
  ngOnDestroy() {
    // window.removeEventListener('beforeunload', this.handleBeforeUnload);

  }
  /*  handleBeforeUnload(event: BeforeUnloadEvent) {
     // Set a returnValue to prompt the user
     const confirmationMessage = 'Are you sure you want to leave?';
     event.preventDefault();// Most browsers display a generic message
     return confirmationMessage; // For some browsers
   } */
}
