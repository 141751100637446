import { createReducer, on } from '@ngrx/store';
import { Umd } from './umd.models';
import { resetElectricityState, resetUmdState } from './umd.actions';
import {
  GetAllElectricitySuccessStatus,
  GetAllUmd,
  getAllUmdFailure,
  getAllUmdSuccess,
  GetElectricitySuccessStatus,
} from './umd.actions';
import {
  CreateUmdFailure,
  CreateUmdSuccess,
  GetUmd,
  createUmd,
  deleteUmduccess,
  getUmdFailure,
  getUmdSuccess,
} from './umd.actions';

export interface UmdState {
  umd: Umd | null;
  createUmdError: any;
  getUmdResponse: any;
  createUmdResponse: any;
  deleteUmdResponse: any;
  getElectricitySuccessResponse: any;
  getAllUmdResponse: any;
  GetAllElectricitySuccessResponse: any;
}

const initialState: UmdState = {
  umd: null,
  createUmdError: null,
  getUmdResponse: null,
  createUmdResponse: null,
  deleteUmdResponse: null,
  getElectricitySuccessResponse: null,
  getAllUmdResponse: null,
  GetAllElectricitySuccessResponse: null,
};

export const UmdReducer = createReducer(
  initialState,
  on(GetUmd, (state) => ({ ...state })),
  on(createUmd, (state) => ({ ...state, error: null })),
  on(CreateUmdSuccess, (state, { createUmdResponse }) => ({
    ...state,
    createUmdResponse,
  })),
  on(CreateUmdFailure, (state, { createUmdError }) => ({
    ...state,
    createUmdError,
  })),
  on(getUmdSuccess, (state, { getUmdResponse }) => ({
    ...state,
    getUmdResponse,
  })),
  on(getUmdFailure, (state, { getUmdError }) => ({
    ...state,
    getUmdError,
  })),
  on(deleteUmduccess, (state, { deleteUmdResponse }) => ({
    ...state,
    deleteUmdResponse,
  })),
  on(
    GetElectricitySuccessStatus,
    (state, { getElectricitySuccessResponse }) => ({
      ...state,
      getElectricitySuccessResponse,
    })
  ),
  on(GetAllUmd, (state) => ({ ...state })),
  on(getAllUmdSuccess, (state, { getAllUmdResponse }) => ({
    ...state,
    getAllUmdResponse,
  })),
  on(getAllUmdFailure, (state, { getAllUmdError }) => ({
    ...state,
    getAllUmdError,
  })),
  on(
    GetAllElectricitySuccessStatus,
    (state, { GetAllElectricitySuccessResponse }) => ({
      ...state,
      GetAllElectricitySuccessResponse,
    })
  ),
  on(resetUmdState, (state) => ({
    ...state,
    getAllUmdResponse: null,
    // Resetting this specific property
  })),
  on(resetElectricityState, (state) => ({
    ...state,
    GetAllElectricitySuccessResponse: null,
    getAllUmdResponse:null
    // Resetting this specific property
  }))
);
