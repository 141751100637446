import { Injectable } from '@angular/core';
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthenticationService } from '../services/auth.service';
import { Observable } from 'rxjs';
import { Roles } from '../../shared/constants/enum';

@Injectable({ providedIn: 'root' })
export class LoginGuard implements CanActivate {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const currentUser = this.authenticationService.currentUserValue;

    if (currentUser) {
      // User is already logged in
      const userRole = currentUser?.currentUserRoleDetails?.role;
      if (userRole === Roles.ADMIN) {
        this.router.navigate(['/admindashboard']);
      } else {
        this.router.navigate(['/dashboard']);
      }
      return false; // Prevent navigation to login page
    }

    return true; // Allow access to login page
  }
}
