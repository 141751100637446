// layout.service.ts
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  // Use BehaviorSubject to hold the isCollapsed state
  private isCollapsedSource = new BehaviorSubject<boolean>(false);
  isCollapsed$ = this.isCollapsedSource.asObservable();

  // Method to toggle isCollapsed state
  toggleisCollapsed(flag:boolean) {
    this.isCollapsedSource.next(flag);
  }

  // Method to set a specific value for isCollapsed
  setisCollapsed(value: boolean) {
    this.isCollapsedSource.next(value);
  }
}
