import { Injectable, Inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  map,
  switchMap,
  catchError,
  exhaustMap,
  tap,
  first,
} from 'rxjs/operators';
import { from, of } from 'rxjs';
import { Router } from '@angular/router';
import { log } from 'console';
import {
  createFaqFailure,
  createFaqSuccess,
  createFaqs,
  delateFaqSuccess,
  deleteFaqFailure,
  deleteFaqs,
  getFaqFailure,
  getFaqSuccess,
  getFaqs,
  updateFaqs,
} from './faq.actions';
import { FaqService } from '../../core/services/faq.service';
import { SharedService } from '../../shared/shared.service';
import { SweetAlertIcon } from '../../shared/constants/enum';
import { LoaderService } from '../../core/services/loader.service';
import { finalize } from 'rxjs/operators';
import { Validations } from '../../shared/constants/validations';

@Injectable()
export class FaqEffects {
  ServiceError = Validations.ServiceError;
  constructor(
    @Inject(Actions) private actions$: Actions,
    private faqService: FaqService,
    private sharedService: SharedService,
    private router: Router,
    private loaderService: LoaderService
  ) {}

  getFaqs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getFaqs),
      exhaustMap(({ pageIndex, pageSize, searchTerm }) => {
        this.loaderService.setLoading(true);
        return this.faqService.getFaqs(pageIndex, pageSize, searchTerm).pipe(
          map((response: any) => {
            console.log(response);
            this.loaderService.setLoading(false);
            if (response && response.statusCode === 200) {
              return getFaqSuccess({ getFaqResponse: response.data });
            } else {
              return getFaqFailure({ getFaqError: response });
            }
          }),
          // catchError((error) => of(error({ error }))) // Closing parenthesis added here
          catchError((error) => {
            console.log(error);
            this.sharedService.showMessageDialog(
              error.error.message,
              this.ServiceError.Error,
              SweetAlertIcon.ERROR
            );
            return of(error({ error }));
          }),
          finalize(() => this.loaderService.setLoading(false))
        );
      })
    )
  );

  createFaq$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createFaqs),
      exhaustMap(({ faq }) => {
        console.log(faq);
        this.loaderService.setLoading(true);
        return this.faqService.createFaqs(faq).pipe(
          map((data: any) => {
            this.loaderService.setLoading(false);
            if (data.statusCode == 201) {
              this.sharedService.showMessageDialog(
                'Success',
                data.message,
                SweetAlertIcon.SUCCESS
              );
              this.router.navigate(['faq']);
              return createFaqSuccess({ createFaqResponse: data }); // Pass the entire response
            } else {
              this.sharedService.showMessageDialog(
                'Failure',
                data.message,
                SweetAlertIcon.ERROR
              );
              return createFaqFailure({ createFaqError: data }); // Pass the entire error response
            }
          }),
          catchError((error) => {
            console.log(error);
            this.sharedService.showMessageDialog(
              error.error.message,
              this.ServiceError.Error,
              SweetAlertIcon.ERROR
            );
            return of(createFaqFailure({ createFaqError: error }));
          }),
          finalize(() => this.loaderService.setLoading(false))
        );
      })
    )
  );

  updateFaq$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateFaqs),
      exhaustMap(({ faq, id }) => {
        this.loaderService.setLoading(true);
        return this.faqService.updateFaqs(faq, id).pipe(
          map((data: any) => {
            this.loaderService.setLoading(false);
            if (data.statusCode == 200) {
              this.sharedService.showMessageDialog(
                'Success',
                data.message,
                SweetAlertIcon.SUCCESS
              );
              this.router.navigate(['faq']);
              return createFaqSuccess({ createFaqResponse: data }); // Pass the entire response
            } else {
              this.sharedService.showMessageDialog(
                'Failure',
                data.message,
                SweetAlertIcon.ERROR
              );
              return createFaqFailure({ createFaqError: data }); // Pass the entire error response
            }
          }),
          catchError((error) => {
            console.log(error);
            this.sharedService.showMessageDialog(
              error.error.message,
              this.ServiceError.Error,
              SweetAlertIcon.ERROR
            );
            return of(createFaqFailure({ createFaqError: error }));
          }),
          finalize(() => this.loaderService.setLoading(false))
        );
      })
    )
  );

  deleteFaq$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteFaqs),
      exhaustMap(({ id }) => {
        console.log(id);
        this.loaderService.setLoading(true);
        return this.faqService.deleteFaqs(id).pipe(
          map((data: any) => {
            this.loaderService.setLoading(false);
            if (data.statusCode == 200) {
              this.sharedService.showMessageDialog(
                'Success',
                data.message,
                SweetAlertIcon.SUCCESS
              );
              // return getFaqs();
              return delateFaqSuccess({ deleteFaqResponse: data }); // Pass the entire response
            } else {
              this.sharedService.showMessageDialog(
                'Failure',
                data.message,
                SweetAlertIcon.ERROR
              );
              return deleteFaqFailure({ deleteFaqError: data }); // Pass the entire error response
            }
          }),
          catchError((error) => {
            console.log(error);
            this.sharedService.showMessageDialog(
              error.error.message,
              this.ServiceError.Error,
              SweetAlertIcon.ERROR
            );
            return of(deleteFaqFailure({ deleteFaqError: error }));
          }),
          finalize(() => this.loaderService.setLoading(false))
        );
      })
    )
  );
}
