// import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
// import { AngularFireMessaging } from '@angular/fire/compat/messaging';
// import { BehaviorSubject, Observable } from 'rxjs';
// import { isPlatformBrowser } from '@angular/common';

// @Injectable({
//   providedIn: 'root',
// })
// export class MessagingService {
//   x: any = null;
//   currentMessage = new BehaviorSubject(this.x);
//   constructor(
//     private angularFireMessaging: AngularFireMessaging,
//     @Inject(PLATFORM_ID) private platformId: Object
//   ) {
//     if (isPlatformBrowser(this.platformId) && 'serviceWorker' in navigator) {
//       navigator.serviceWorker.addEventListener('message', (event) => {
//         console.log('Service Worker Message Received: ', event.data);
//         // this.currentMessage.next(event.data);
//       });
//     }
//   }

//   requestPermission() {
//     this.angularFireMessaging.requestToken.subscribe({
//       next: (token) => {
//         console.log("tokenhello",token);
//       },
//       error: (err) => {
//         console.log('unable to get permission to notify....', err);
//       },
//     });
//   }
  
//   receiveMessage() {
//     this.angularFireMessaging.messages.subscribe((payload) => {
//       console.log('new message received. ', payload);
//       this.currentMessage.next(payload);
//     });
//   }

//   getNewNotification(): Observable<any> {
//     return this.currentMessage.asObservable();
//   }
// }



import { Inject, Injectable, PLATFORM_ID, OnInit } from '@angular/core'; 
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { BehaviorSubject, Observable } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class MessagingService implements OnInit {
  private x: any = null;
   currentMessage = new BehaviorSubject<any>(this.x);

  constructor(
    private angularFireMessaging: AngularFireMessaging,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.checkNotificationPermission();
    }
  }
  checkNotificationPermission() {
    if (isPlatformBrowser(this.platformId)) {
      if (!('Notification' in window)) {
        console.log('This browser does not support desktop notifications.');
        return;
      }
  
      if (Notification.permission === 'default') {
        // Permission is not yet granted or denied
        this.requestNotificationPermission();
      } else if (Notification.permission === 'granted') {
        // Permission already granted
        this.initializeMessaging();
      } else if (Notification.permission === 'denied') {
        console.log('Notification permission has been denied.');
      }
    }
  }

  private requestNotificationPermission() {
    Notification.requestPermission().then(permission => {
      if (permission === 'granted') {
        console.log('Notification permission granted.');
        this.initializeMessaging();
      } else {
        console.log('Notification permission denied.');
      }
    }).catch(err => {
      console.error('Error requesting notification permission:', err);
    });
  }

  private initializeMessaging() {
    if (isPlatformBrowser(this.platformId) && 'serviceWorker' in navigator) {
      navigator.serviceWorker.addEventListener('message', event => {
        console.log('Service Worker Message Received: ', event.data);
        this.currentMessage.next(event.data);
      });
  
      this.angularFireMessaging.requestToken.subscribe({
        next: (token) => {
          console.log('FCM Token:', token);
        },
        error: (err) => {
          console.log('Unable to get permission to notify...', err);
        },
      });
  
      this.receiveMessage();
    }
  }
  

//   private initializeMessaging() {
//   if (isPlatformBrowser(this.platformId) && 'serviceWorker' in navigator) {
//     navigator.serviceWorker.addEventListener('message', event => {
//       console.log('Service Worker Message Received: ', event.data);
//       this.currentMessage.next(event.data);  // Just forward the message data
//     });

//     this.angularFireMessaging.requestToken.subscribe({
//       next: (token) => {
//         console.log('FCM Token:', token);
//       },
//       error: (err) => {
//         console.log('Unable to get permission to notify...', err);
//       },
//     });

//     this.receiveMessage();
//   }
// }


  receiveMessage() {
    this.angularFireMessaging.messages.subscribe(payload => {
      console.log('New message received.', payload);
      // this.currentMessage.next(payload);
    });
  }

  getNewNotification(): Observable<any> {
    return this.currentMessage.asObservable();
  }
}
