import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { Router } from '@angular/router';
import { SharedService } from '../../shared/shared.service';
import { map, catchError, exhaustMap, finalize } from 'rxjs/operators';
import { LoaderService } from '../../core/services/loader.service';
import { Validations } from '../../shared/constants/validations';
import { SweetAlertIcon } from '../../shared/constants/enum';
import {
  getWalletlist,
  getWalletSuccess,
  getWalletFailure,
  getRechargeHistorylist,
  getwalletHistorylist,
  getwalletHistorylistFailure,
  getwalletHistorylistSuccess,
  getAllWalletFailure,
  getAllWalletSuccess,
  getAllWalletlist,
  getAllWalletHistorySuccess,
  getAllWalletHistoryFailure,
  getAllWalletHistorylist,
  getAllRechargeHistorySuccess,
  getAllRechargeHistorylist,
  getAllRechargeHistoryFailure,
  getwalletPyByCashFilure,
  getwalletPyByCashSuccess,
  getwalletPyByCash,
} from './wallet.action';
import { WalletService } from '../../core/services/wallet.services';
import {
  getRechargeHistoryFailure,
  getRechargeHistorySuccess,
} from './wallet.action';
import {
  getWalletHistorylist,
  getWalletHistorySuccess,
  getWalletHistoryFailure,
} from './wallet.action';

@Injectable()
export class WalletsEffects {
  ServiceError = Validations.ServiceError;

  constructor(
    private actions$: Actions,
    private router: Router,
    private loaderService: LoaderService,
    private sharedService: SharedService,
    private walletService: WalletService
  ) {}

  getWallet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getWalletlist),
      exhaustMap(
        ({ pageIndex, pageSize, searchTerm, projectId, filter, orderBy }) => {
          // if (!searchTerm) {
          this.loaderService.setLoading(true);
          // }
          return this.walletService
            .getWallets(
              pageIndex,
              pageSize,
              searchTerm,
              projectId,
              filter,
              orderBy
            )
            .pipe(
              map((response: any) => {
                this.loaderService.setLoading(false);
                console.log(response);
                if (response && response.statusCode === 200) {
                  return getWalletSuccess({
                    getWalletResponse: response.data,
                  });
                } else {
                  return getWalletFailure({ getwalletError: response });
                }
              }),
              catchError((error) => {
                console.log(error);
                this.sharedService.showMessageDialog(
                  error.error.message,
                  this.ServiceError.Error,
                  SweetAlertIcon.ERROR
                );
                return of(getWalletFailure({ getwalletError: error }));
              }),
              finalize(() => this.loaderService.setLoading(false))
            );
        }
      )
    )
  );

  getAllWallet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAllWalletlist),
      exhaustMap(
        ({ pageIndex, pageSize, searchTerm, projectId, filter, orderBy }) => {
          if (!searchTerm) {
            this.loaderService.setLoading(true);
          }
          return this.walletService
            .getWallets(
              pageIndex,
              pageSize,
              searchTerm,
              projectId,
              filter,
              orderBy
            )
            .pipe(
              map((response: any) => {
                this.loaderService.setLoading(false);
                console.log(response);
                if (response && response.statusCode === 200) {
                  return getAllWalletSuccess({
                    getAllWalletResponse: response.data,
                  });
                } else {
                  return getAllWalletFailure({ getAllwalletError: response });
                }
              }),
              catchError((error) => {
                console.log(error);
                this.sharedService.showMessageDialog(
                  error.error.message,
                  this.ServiceError.Error,
                  SweetAlertIcon.ERROR
                );
                return of(getAllWalletFailure({ getAllwalletError: error }));
              }),
              finalize(() => this.loaderService.setLoading(false))
            );
        }
      )
    )
  );

  getwalletHistorylist$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getwalletHistorylist),
      exhaustMap(({ consumerId, projectId }) => {
        return this.walletService.getwalletHistory(consumerId, projectId).pipe(
          map((response: any) => {
            this.loaderService.setLoading(false);
            console.log(response);
            if (response && response.statusCode === 200) {
              return getwalletHistorylistSuccess({
                walletHistroeyResponse: response.data,
              });
            } else {
              return getwalletHistorylistFailure({
                walletHistroeyfailure: response,
              });
            }
          }),
          catchError((error) => {
            console.log(error);
            this.sharedService.showMessageDialog(
              error.error.message,
              this.ServiceError.Error,
              SweetAlertIcon.ERROR
            );
            return of(
              getwalletHistorylistFailure({ walletHistroeyfailure: error })
            );
          }),
          finalize(() => this.loaderService.setLoading(false))
        );
      })
    )
  );

  getAllWalletHistorylist$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAllWalletHistorylist),
      exhaustMap(
        ({ pageIndex, pageSize, searchTerm, walletId, filter, orderBy }) => {
          if (!searchTerm) {
            this.loaderService.setLoading(true);
          }
          return this.walletService
            .getWalletsHistory(
              pageIndex,
              pageSize,
              searchTerm,
              walletId,
              filter,
              orderBy
            )
            .pipe(
              map((response: any) => {
                this.loaderService.setLoading(false);
                console.log(response);
                if (response && response.statusCode === 200) {
                  return getAllWalletHistorySuccess({
                    getAllWalletHistoryResponse: response.data,
                  });
                } else {
                  return getAllWalletHistoryFailure({
                    getAllwalletHistoryError: response,
                  });
                }
              }),
              catchError((error) => {
                console.log(error);
                this.sharedService.showMessageDialog(
                  error.error.message,
                  this.ServiceError.Error,
                  SweetAlertIcon.ERROR
                );
                return of(
                  getAllWalletHistoryFailure({
                    getAllwalletHistoryError: error,
                  })
                );
              }),
              finalize(() => this.loaderService.setLoading(false))
            );
        }
      )
    )
  );
  getWalletHistory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getWalletHistorylist),
      exhaustMap(
        ({ pageIndex, pageSize, searchTerm, walletId, filter, orderBy }) => {
          // if (!searchTerm) {
          this.loaderService.setLoading(true);
          // }
          return this.walletService
            .getWalletsHistory(
              pageIndex,
              pageSize,
              searchTerm,
              walletId,
              filter,
              orderBy
            )
            .pipe(
              map((response: any) => {
                this.loaderService.setLoading(false);
                console.log(response);
                if (response && response.statusCode === 200) {
                  return getWalletHistorySuccess({
                    getWalletHistoryResponse: response.data,
                  });
                } else {
                  return getWalletHistoryFailure({
                    getwalletHistoryError: response,
                  });
                }
              }),
              catchError((error) => {
                console.log(error);
                this.sharedService.showMessageDialog(
                  error.error.message,
                  this.ServiceError.Error,
                  SweetAlertIcon.ERROR
                );
                return of(
                  getWalletHistoryFailure({ getwalletHistoryError: error })
                );
              }),
              finalize(() => this.loaderService.setLoading(false))
            );
        }
      )
    )
  );

  getRechargeHistory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getRechargeHistorylist),
      exhaustMap(
        ({ pageIndex, pageSize, searchTerm, walletId, filter, orderBy }) => {
          if (!searchTerm) {
            this.loaderService.setLoading(true);
          }
          return this.walletService
            .getRechargeHistory(
              pageIndex,
              pageSize,
              searchTerm,
              walletId,
              filter,
              orderBy
            )
            .pipe(
              map((response: any) => {
                this.loaderService.setLoading(false);
                console.log(response);
                if (response && response.statusCode === 200) {
                  return getRechargeHistorySuccess({
                    getRechargeHistoryResponse: response.data,
                  });
                } else {
                  return getRechargeHistoryFailure({
                    getRechargeHistoryError: response,
                  });
                }
              }),
              catchError((error) => {
                console.log(error);
                this.sharedService.showMessageDialog(
                  error.error.message,
                  this.ServiceError.Error,
                  SweetAlertIcon.ERROR
                );
                return of(
                  getRechargeHistoryFailure({ getRechargeHistoryError: error })
                );
              }),
              finalize(() => this.loaderService.setLoading(false))
            );
        }
      )
    )
  );

  getAllRechargeHistory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAllRechargeHistorylist),
      exhaustMap(
        ({ pageIndex, pageSize, searchTerm, walletId, filter, orderBy }) => {
          if (!searchTerm) {
            this.loaderService.setLoading(true);
          }
          return this.walletService
            .getRechargeHistory(
              pageIndex,
              pageSize,
              searchTerm,
              walletId,
              filter,
              orderBy
            )
            .pipe(
              map((response: any) => {
                this.loaderService.setLoading(false);
                console.log(response);
                if (response && response.statusCode === 200) {
                  return getAllRechargeHistorySuccess({
                    getAllRechargeHistoryResponse: response.data,
                  });
                } else {
                  return getAllRechargeHistoryFailure({
                    getAllRechargeHistoryError: response,
                  });
                }
              }),
              catchError((error) => {
                console.log(error);
                this.sharedService.showMessageDialog(
                  error.error.message,
                  this.ServiceError.Error,
                  SweetAlertIcon.ERROR
                );
                return of(
                  getAllRechargeHistoryFailure({
                    getAllRechargeHistoryError: error,
                  })
                );
              }),
              finalize(() => this.loaderService.setLoading(false))
            );
        }
      )
    )
  );

  getwalletPyByCash$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getwalletPyByCash),
      exhaustMap((cashDeatils) => {
        this.loaderService.setLoading(true);
        return this.walletService.walletpayBycash(cashDeatils.cashDeatils).pipe(
          map((response: any) => {
            console.log(response);
            this.loaderService.setLoading(false);
            if (response && response.statusCode === 201) {
              this.sharedService.showMessageDialog(
                'Success',
                response.message,
                SweetAlertIcon.SUCCESS
              );
              return getwalletPyByCashSuccess({
                walletPyByCashResponse: response.data,
              });
            } else {
              this.sharedService.showMessageDialog(
                'Failure',
                response.message,
                SweetAlertIcon.ERROR
              );
              return getwalletPyByCashFilure({
                walletPyByCashfailure: response,
              });
            }
          }),
          catchError((error) => {
            console.log(error);
            this.sharedService.showMessageDialog(
              error.error.message,
              this.ServiceError.Error,
              SweetAlertIcon.ERROR
            );
            return of(
              getwalletPyByCashFilure({ walletPyByCashfailure: error })
            );
          }),
          finalize(() => this.loaderService.setLoading(false))
        );
      })
    )
  );
}
