import { createReducer, on } from '@ngrx/store';
import {
  getSuperAdminDashboardRevenueSuccess,
  getSuperAdminDashboardRevenuDetials_BasedOn_DateSuccess,
  getAdminDashboard,
  getAdminDashboardSuccess,
  getAdminDashboardFailure,
  getAdminFlatwiseConsmptionsDashboard,
  getAdminFlatwiseConsmptionsDashboardSuccess,
  getAdminFlatwiseConsmptionsDashboardFailure,
  getConumerPostpaidDashboard,
  getConumerPostpaidDashboardSuccess,
  getConumerPostpaidDashboardFailure,
  getConumerPostpaidConsumptionDashboard,
  getConumerPostpaidConsumptionDashboardSuccess,
  getConumerPostpaidConsumptionDashboardFailure,
  getConumerConnectionsData,
  getConumerConnectionsDataSuccess,
  getConumerConnectionsDataFailure,
} from './dashboard.actions';
import {
  getSuperAdminDashboard,
  getSuperAdminDashboardSuccess,
} from './dashboard.actions';
import { AnyMxRecord } from 'dns';

export interface DashboardState {
  getSuperadmindashboardResponse: any;
  getSuperadmindashboardRevenueResponse: any;
  getSuperadmindashboardRevenueOnDateResponse: any;
  getadmindashboardResponse: any;
  getadminFlatwiseConsmptionsdashboardResponse: any;
  getConumerPostpaidResponse: any;
  getConumerPostpaidConsumptionResponse: any;
  getConumerConnectionsDataResponse: any

}

const initialState: DashboardState = {
  getSuperadmindashboardResponse: null,
  getSuperadmindashboardRevenueResponse: null,
  getSuperadmindashboardRevenueOnDateResponse: null,
  getadmindashboardResponse: null,
  getadminFlatwiseConsmptionsdashboardResponse: null,
  getConumerPostpaidResponse: null,
  getConumerPostpaidConsumptionResponse: null,
  getConumerConnectionsDataResponse: null
};

export const dashboardReducer = createReducer(
  initialState,
  on(getSuperAdminDashboard, (state) => ({
    ...state,
    error: null,
    loading: true,
  })),
  on(
    getSuperAdminDashboardSuccess,
    (state, { getSuperadmindashboardResponse }) => ({
      ...state,
      getSuperadmindashboardResponse,
    })
  ),
  on(
    getSuperAdminDashboardRevenueSuccess,
    (state, { getSuperadmindashboardRevenueResponse }) => ({
      ...state,
      getSuperadmindashboardRevenueResponse,
    })
  ),
  on(
    getSuperAdminDashboardRevenuDetials_BasedOn_DateSuccess,
    (state, { getSuperadmindashboardRevenueOnDateResponse }) => ({
      ...state,
      getSuperadmindashboardRevenueOnDateResponse,
    })
  ),


  //admin dashboard
  on(getAdminDashboard, (state) => { return { ...state, loading: true, error: null }; }),
  on(getAdminDashboardSuccess, (state, { getadmindashboardResponse }) => ({ ...state, getadmindashboardResponse })),
  on(getAdminDashboardFailure, (state, { getadmindashboardResponseError }) => ({ ...state, getadmindashboardResponseError })),

  on(getAdminFlatwiseConsmptionsDashboard, (state) => { return { ...state, loading: true, error: null }; }),
  on(getAdminFlatwiseConsmptionsDashboardSuccess, (state, { getadminFlatwiseConsmptionsdashboardResponse }) => ({ ...state, getadminFlatwiseConsmptionsdashboardResponse })),
  on(getAdminFlatwiseConsmptionsDashboardFailure, (state, { getadminTopConsmptionsdashboardResponseError }) => ({ ...state, getadminTopConsmptionsdashboardResponseError })),

  //consumer dashboard

  on(getConumerPostpaidDashboard, (state) => { return { ...state, loading: true, error: null }; }),
  on(getConumerPostpaidDashboardSuccess, (state, { getConumerPostpaidResponse }) => ({ ...state, getConumerPostpaidResponse })),
  on(getConumerPostpaidDashboardFailure, (state, { getConumerPostpaidResponseError }) => ({ ...state, getConumerPostpaidResponseError })),


  on(getConumerPostpaidConsumptionDashboard, (state) => { return { ...state, loading: true, error: null }; }),
  on(getConumerPostpaidConsumptionDashboardSuccess, (state, { getConumerPostpaidConsumptionResponse }) => ({ ...state, getConumerPostpaidConsumptionResponse })),
  on(getConumerPostpaidConsumptionDashboardFailure, (state, { getConumerPostpaidConsumptionResponseError }) => ({ ...state, getConumerPostpaidConsumptionResponseError })),


  on(getConumerConnectionsData, (state) => { return { ...state, loading: true, error: null }; }),
  on(getConumerConnectionsDataSuccess, (state, { getConumerConnectionsDataResponse }) => ({ ...state, getConumerConnectionsDataResponse })),
  on(getConumerConnectionsDataFailure, (state, { getConumerConnectionsDataResponseError }) => ({ ...state, getConumerConnectionsDataResponseError })),





















);






