import { FaqState, faqReducer } from './Faq/faq.reducer';
import {
  AuthenticationState,
  authenticationReducer,
} from './Authentication/authentication.reducer';

import { ActionReducerMap } from '@ngrx/store';
import {
  FilemanageReducer,
  FilemanagerState,
} from './filemanager/filemanager.reducer';
import { EcoOrderState, OrderReducer } from './orders/order.reducer';
import { CartReducer, CartState } from './Cart/cart.reducer';
import { UserListReducer, UserlistState } from './UserList/userlist.reducer';
import { JoblistReducer, joblistState } from './Job/job.reducer';
import {
  CandidateReducer,
  CandidateState,
} from './Candidate/candidate.reducer';
import {
  InvoiceDataReducer,
  InvoiceDataState,
} from './Invoices/invoices.reducer';
import { ChatReducer, ChatState } from './Chat/chat.reducer';
import { tasklistReducer, tasklistState } from './Tasks/tasks.reducer';
import { OrderdataState, OrdersReducer } from './Crypto/crypto.reducer';
import { LayoutState, layoutReducer } from './layouts/layouts.reducer';
//import { CustomerReducer, CustomerState } from "./customer/consumer.reducer";

import { MailReducer, MailState } from './Email/email.reducer';
import { MasterState, masterReducer } from './Masters/masters.reducer';
import { SupplierState, SuppliersReducer } from './Suppliers/suppliers.reducer';
import { ProjectState, ProjectsReducer } from './Projects/projects.reducer';
import { GatewayState, gatewayReducer } from './GateWay/gateway.reducer';
import {
  MonitoringState,
  monitoringReducer,
} from './Monitoring/monitoring.reducer';
import { ConsumerReducer, ConsumerState } from './customer/consumer.reducer';
import {
  residentialUnitReducer,
  residentialUnitState,
} from './residential-unit/residentialUnit.reducer';

import { UmdState, UmdReducer } from './Umd/umd.reducer';
import {
  UtilitiesState,
  UtilitiesReducer,
} from './Utilities/utilities.reducer';
import { MetersState, metersReducer } from './meters/meters.reducer';
import { WalletReducer, WalletState } from './Wallet/wallet.reducer';
import { Billsreducer, billsState } from './Bills/bills.reducer';
import {
  BillSettingreducer,
  billSettingState,
} from './billsSettings/billSettings.reducer';
import {
  dashboardReducer,
  DashboardState,
} from './dashboard/dashboard.reducer';
import { MappingState, mappingsReducer } from './mapping/mapping.reducer';

export interface RootReducerState {
  dashboard: DashboardState;
  mapping: MappingState;
  layout: LayoutState;
  auth: AuthenticationState;
  faq: FaqState;
  bills: billsState;
  billSetting: billSettingState;
  master: MasterState;
  consumer: ConsumerState;
  residentilaUnit: residentialUnitState;
  supplier: SupplierState;
  projects: ProjectState;
  gateway: GatewayState;
  monitoring: MonitoringState;
  Filelist: FilemanagerState;
  EcoOrderList: EcoOrderState;
  CartList: CartState;
  umd: UmdState;
  utilities: UtilitiesState;
  meter: MetersState;
  wallet: WalletState;
  userList: UserlistState;
  Joblist: joblistState;
  CandidateList: CandidateState;
  InvoiceList: InvoiceDataState;
  chatList: ChatState;
  Tasklist: tasklistState;
  Order: OrderdataState;
  // Customer: CustomerState;
  Maillist: MailState;
}

export const rootReducer: ActionReducerMap<RootReducerState> = {
  dashboard: dashboardReducer,
  mapping: mappingsReducer,
  auth: authenticationReducer,
  faq: faqReducer,
  master: masterReducer,
  supplier: SuppliersReducer,
  projects: ProjectsReducer,
  gateway: gatewayReducer,
  bills: Billsreducer,
  billSetting: BillSettingreducer,
  monitoring: monitoringReducer,
  umd: UmdReducer,
  utilities: UtilitiesReducer,
  meter: metersReducer,
  wallet: WalletReducer,
  layout: layoutReducer,
  Filelist: FilemanageReducer,
  EcoOrderList: OrderReducer,
  CartList: CartReducer,
  residentilaUnit: residentialUnitReducer,
  userList: UserListReducer,
  consumer: ConsumerReducer,
  Joblist: JoblistReducer,
  CandidateList: CandidateReducer,
  InvoiceList: InvoiceDataReducer,
  chatList: ChatReducer,
  Tasklist: tasklistReducer,
  Order: OrdersReducer,
  // Customer: CustomerReducer,
  Maillist: MailReducer,
};
