import { createReducer, on } from '@ngrx/store';
import { Master } from './masters.models';
import {
  CreateMasterFailure,
  CreateMasterSuccess,
  Getmaster,
  createMaster,
  getMastersFailure,
  getMastersSuccess,
  GetAllmaster,
  getAllMastersSuccess,
  getAllMastersFailure,
  resetState,
} from './masters.actions';

export interface MasterState {
  master: Master | null;
  createMasterError: any;
  getMasterResponse: any;
  createMasterResponse: any;
  getAllMasterResponse: any;
}

const initialState: MasterState = {
  master: null,
  createMasterError: null,
  getMasterResponse: null,
  createMasterResponse: null,
  getAllMasterResponse: null,
};

export const masterReducer = createReducer(
  initialState,
  on(Getmaster, (state) => ({ ...state })),
  on(createMaster, (state) => ({ ...state, error: null })),
  on(CreateMasterSuccess, (state, { createMasterResponse }) => ({
    ...state,
    createMasterResponse,
  })),
  on(CreateMasterFailure, (state, { createMasterError }) => ({
    ...state,
    createMasterError,
  })),
  on(getMastersSuccess, (state, { getMasterResponse }) => ({
    ...state,
    getMasterResponse,
  })),
  on(getMastersFailure, (state, { getMasterError }) => ({
    ...state,
    getMasterError,
  })),
  on(GetAllmaster, (state) => ({ ...state })),
  on(getAllMastersSuccess, (state, { getAllMasterResponse }) => ({
    ...state,
    getAllMasterResponse,
  })),
  on(getAllMastersFailure, (state, { getAllMasterError }) => ({
    ...state,
    getAllMasterError,
  })),
  on(resetState, (state) => ({
    ...state,
    getAllMasterResponse: null,
    // Resetting this specific property
  }))
);
