import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { Router } from '@angular/router';
import { SharedService } from '../../shared/shared.service';
import { map, catchError, exhaustMap } from 'rxjs/operators';
import { LoaderService } from '../../core/services/loader.service';
import { finalize } from 'rxjs/operators';
import { Validations } from '../../shared/constants/validations';
import { SweetAlertIcon } from '../../shared/constants/enum';
import {
  getResidentialUnitlist,
  getResidentialUnitSuccess,
  getResidentialUnitFailure,
  createResidentialUnit,
  addResidentialUnitSuccess,
  addResidentialUnitFailure,
  updateResidentialUnit,
  updateResidentialUnitSuccess,
  updateResidentialUnitFailure,
  getOccupationHistorylist,
  getOccupationHistorySuccess,
  getOccupationHistoryFailure,
  getResidentialUnitByIdlist,
  getResidentialUnitByIdSuccess,
  getResidentialUnitByIdFailure,
  getAllResidentialUnitSuccess,
  getAllResidentialUnitFailure,
  getAllResidentialUnitlist,
  getOccupationHistoryExpotyExcelFailure,
  getOccupationHistoryExpotyExcelSuccess,
  getOccupationHistoryExpotyExcellist,
  getResidentialHistoryFailure,
  getResidentialHistorySuccess,
  getResidentialHistorylist,
  getExportResidentialHistorySuccess,
  getExportResidentialHistoryFailure,
  getExportResidentialHistory,
} from './residentialUnit.action';
import { ResidentialunitService } from '../../core/services/residentialunit.service';

@Injectable()
export class residentialUnitEffects {
  ServiceError = Validations.ServiceError;

  createResidentialUnit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createResidentialUnit),
      exhaustMap((ResidentialUnit) => {
        console.log(ResidentialUnit);
        this.loaderService.setLoading(true);
        return this.residentialunitService
          .createresidentilaUnit(ResidentialUnit.ResidentialUnit)
          .pipe(
            map((response: any) => {
              console.log(response);
              this.loaderService.setLoading(false);
              if (response && response.statusCode === 201) {
                this.sharedService.showMessageDialog(
                  'Success',
                  response.message,
                  SweetAlertIcon.SUCCESS
                );
                this.router.navigate(['/residentialUnit']);
                return addResidentialUnitSuccess({
                  createResidentialUnitResponse: response,
                });
              } else {
                this.sharedService.showMessageDialog(
                  'Failure',
                  response.message,
                  SweetAlertIcon.ERROR
                );
                return addResidentialUnitFailure({
                  createResidentialUnitError: response,
                });
              }
            }),
            catchError((error) => {
              console.log(error);

              this.sharedService.showMessageDialog(
                error.error.message,
                this.ServiceError.Error,
                SweetAlertIcon.ERROR
              );
              return of(
                addResidentialUnitFailure({ createResidentialUnitError: error })
              );
            }),
            finalize(() => this.loaderService.setLoading(false))
          );
      })
    )
  );

  getResidentialUnitlist$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getResidentialUnitlist),
      exhaustMap(
        ({ pageIndex, pageSize, searchTerm, projectId, filter, orderBy }) => {
          // if (!searchTerm) {
          this.loaderService.setLoading(true);
          // }
          return this.residentialunitService
            .getResidentialUnit(
              pageIndex,
              pageSize,
              searchTerm,
              projectId,
              filter,
              orderBy
            )
            .pipe(
              map((response: any) => {
                this.loaderService.setLoading(false);
                console.log(response);
                if (response && response.statusCode === 200) {
                  return getResidentialUnitSuccess({
                    getResidentialUnitResponse: response.data,
                  });
                } else {
                  return getResidentialUnitFailure({
                    getResidentialUnitError: response,
                  });
                }
              }),
              catchError((error) => {
                console.log(error);
                this.sharedService.showMessageDialog(
                  error.error.message,
                  this.ServiceError.Error,
                  SweetAlertIcon.ERROR
                );
                return of(
                  getResidentialUnitFailure({
                    getResidentialUnitError: error,
                  })
                );
              }),
              finalize(() => this.loaderService.setLoading(false))
            );
        }
      )
    )
  );

  getOccupationHistorylist$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getOccupationHistorylist),
      exhaustMap(({ pageIndex, pageSize, searchTerm, id, filter, orderBy }) => {
        if (!searchTerm) {
          this.loaderService.setLoading(true);
        }
        return this.residentialunitService
          .getOccupationHistoryUnit(
            pageIndex,
            pageSize,
            searchTerm,
            id,
            filter,
            orderBy
          )
          .pipe(
            map((response: any) => {
              this.loaderService.setLoading(false);
              console.log(response);
              if (response && response.statusCode === 200) {
                return getOccupationHistorySuccess({
                  getOccupationHistoryResponse: response.data,
                });
              } else {
                return getOccupationHistoryFailure({
                  getOccupationHistoryUnitError: response,
                });
              }
            }),
            catchError((error) => {
              console.log(error);
              this.sharedService.showMessageDialog(
                error.error.message,
                this.ServiceError.Error,
                SweetAlertIcon.ERROR
              );
              return of(
                getOccupationHistoryFailure({
                  getOccupationHistoryUnitError: error,
                })
              );
            }),
            finalize(() => this.loaderService.setLoading(false))
          );
      })
    )
  );

  getResidentialHistorylist$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getResidentialHistorylist),
      exhaustMap(({ pageIndex, pageSize, searchTerm, id, filter, orderBy }) => {
        if (!searchTerm) {
          this.loaderService.setLoading(true);
        }
        return this.residentialunitService
          .getresidentialunitumdhistory(
            pageIndex,
            pageSize,
            searchTerm,
            id,
            filter,
            orderBy
          )

          .pipe(
            map((response: any) => {
              this.loaderService.setLoading(false);
              console.log(response);
              if (response && response.statusCode === 200) {
                return getResidentialHistorySuccess({
                  getResidentialHistoryResponse: response.data,
                });
              } else {
                return getResidentialHistoryFailure({
                  getResidentialHistoryUnitError: response,
                });
              }
            }),
            catchError((error) => {
              console.log(error);
              this.sharedService.showMessageDialog(
                error.error.message,
                this.ServiceError.Error,
                SweetAlertIcon.ERROR
              );
              return of(
                getResidentialHistoryFailure({
                  getResidentialHistoryUnitError: error,
                })
              );
            }),
            finalize(() => this.loaderService.setLoading(false))
          );
      })
    )
  );

  getOccupationHistoryExpotyExcellist$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getOccupationHistoryExpotyExcellist),
      exhaustMap(({ pageIndex, pageSize, searchTerm, id, filter, orderBy }) => {
        if (!searchTerm) {
          this.loaderService.setLoading(true);
        }
        return this.residentialunitService
          .getOccupationHistoryUnit(
            pageIndex,
            pageSize,
            searchTerm,
            id,
            filter,
            orderBy
          )
          .pipe(
            map((response: any) => {
              this.loaderService.setLoading(false);
              console.log(response);
              if (response && response.statusCode === 200) {
                return getOccupationHistoryExpotyExcelSuccess({
                  getOccupationHistoryExpotyExcelResponse: response.data,
                });
              } else {
                return getOccupationHistoryExpotyExcelFailure({
                  getOccupationHistoryExpotyExcelUnitError: response,
                });
              }
            }),
            catchError((error) => {
              console.log(error);
              this.sharedService.showMessageDialog(
                error.error.message,
                this.ServiceError.Error,
                SweetAlertIcon.ERROR
              );
              return of(
                getOccupationHistoryExpotyExcelFailure({
                  getOccupationHistoryExpotyExcelUnitError: error,
                })
              );
            }),
            finalize(() => this.loaderService.setLoading(false))
          );
      })
    )
  );

  getExportResidentialHistory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getExportResidentialHistory),
      exhaustMap(({ pageIndex, pageSize, searchTerm, id, filter, orderBy }) => {
        if (!searchTerm) {
          this.loaderService.setLoading(true);
        }
        return this.residentialunitService
          .getresidentialunitumdhistory(
            pageIndex,
            pageSize,
            searchTerm,
            id,
            filter,
            orderBy
          )
          .pipe(
            map((response: any) => {
              this.loaderService.setLoading(false);
              console.log(response);
              if (response && response.statusCode === 200) {
                return getExportResidentialHistorySuccess({
                  getExportResidentialHistoryResponse: response.data,
                });
              } else {
                return getExportResidentialHistoryFailure({
                  getExportResidentialHistoryError: response,
                });
              }
            }),
            catchError((error) => {
              console.log(error);
              this.sharedService.showMessageDialog(
                error.error.message,
                this.ServiceError.Error,
                SweetAlertIcon.ERROR
              );
              return of(
                getExportResidentialHistoryFailure({
                  getExportResidentialHistoryError: error,
                })
              );
            }),
            finalize(() => this.loaderService.setLoading(false))
          );
      })
    )
  );

  getResidentialUnitByIdlist$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getResidentialUnitByIdlist),
      exhaustMap(({ id }) => {
        return this.residentialunitService.getResidentialUnitById(id).pipe(
          map((response: any) => {
            this.loaderService.setLoading(false);
            console.log(response);
            if (response && response.statusCode === 200) {
              return getResidentialUnitByIdSuccess({
                getResidentialUnitByIdResponse: response.data,
              });
            } else {
              return getResidentialUnitByIdFailure({
                getResidentialUnitByIdError: response,
              });
            }
          }),
          catchError((error) => {
            console.log(error);
            this.sharedService.showMessageDialog(
              error.error.message,
              this.ServiceError.Error,
              SweetAlertIcon.ERROR
            );
            return of(
              getResidentialUnitByIdFailure({
                getResidentialUnitByIdError: error,
              })
            );
          }),
          finalize(() => this.loaderService.setLoading(false))
        );
      })
    )
  );

  updateResidentialUnit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateResidentialUnit),
      exhaustMap((ResidentialUnit, id) => {
        console.log(ResidentialUnit, '-----', id);
        this.loaderService.setLoading(true);
        return this.residentialunitService
          .updatetResidentialUnitById(
            ResidentialUnit.ResidentialUnit,
            ResidentialUnit.id
          )
          .pipe(
            map((response: any) => {
              this.loaderService.setLoading(false);
              if (response && response.statusCode === 200) {
                this.sharedService.showMessageDialog(
                  'Success',
                  response.message,
                  SweetAlertIcon.SUCCESS
                );
                this.router.navigate(['/residentialUnit']);
                return updateResidentialUnitSuccess({
                  updateResidentialUnitResponse: response,
                });
              } else {
                this.sharedService.showMessageDialog(
                  'Failure',
                  response.message,
                  SweetAlertIcon.ERROR
                );
                return updateResidentialUnitFailure({
                  updateResidentialUnitError: response,
                });
              }
            }),
            catchError((error) => {
              console.log(error);
              this.sharedService.showMessageDialog(
                error.error.message,
                this.ServiceError.Error,
                SweetAlertIcon.ERROR
              );
              return of(
                updateResidentialUnitFailure({
                  updateResidentialUnitError: error,
                })
              );
            }),
            finalize(() => this.loaderService.setLoading(false))
          );
      })
    )
  );

  getAllResidentialUnitlist$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAllResidentialUnitlist),
      exhaustMap(
        ({ pageIndex, pageSize, searchTerm, projectId, filter, orderBy }) => {
          if (!searchTerm) {
            this.loaderService.setLoading(true);
          }
          return this.residentialunitService
            .getResidentialUnit(
              pageIndex,
              pageSize,
              searchTerm,
              projectId,
              filter,
              orderBy
            )
            .pipe(
              map((response: any) => {
                this.loaderService.setLoading(false);
                console.log(response);
                if (response && response.statusCode === 200) {
                  return getAllResidentialUnitSuccess({
                    getAllResidentialUnit_Response: response.data,
                  });
                } else {
                  return getAllResidentialUnitFailure({
                    getAllResidentialUnitError: response,
                  });
                }
              }),
              catchError((error) => {
                console.log(error);
                this.sharedService.showMessageDialog(
                  error.error.message,
                  this.ServiceError.Error,
                  SweetAlertIcon.ERROR
                );
                return of(
                  getAllResidentialUnitFailure({
                    getAllResidentialUnitError: error,
                  })
                );
              }),
              finalize(() => this.loaderService.setLoading(false))
            );
        }
      )
    )
  );
  constructor(
    private actions$: Actions,

    // private CrudService: CrudService,
    private residentialunitService: ResidentialunitService,
    private router: Router,
    private loaderService: LoaderService,
    private sharedService: SharedService
  ) {}
}
