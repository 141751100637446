import { Action, createReducer, on } from '@ngrx/store';

import {
  createResidentialUnit,
  getResidentialUnitlist,
  addResidentialUnitSuccess,
  addResidentialUnitFailure,
  getResidentialUnitSuccess,
  getResidentialUnitFailure,
  getOccupationHistoryFailure,
  getOccupationHistorySuccess,
  getResidentialUnitByIdSuccess,
  getResidentialUnitByIdFailure,
  resetResidentialUnitState,

  getAllResidentialUnitSuccess,
  resetState,
  getOccupationHistoryExpotyExcellist,
  getOccupationHistoryExpotyExcelSuccess,
  getOccupationHistoryExpotyExcelFailure,
  resetOccupaintHistoryState,
  getResidentialHistorylist,
  getResidentialHistorySuccess,
  getResidentialHistoryFailure,
  getExportResidentialHistory,
  getExportResidentialHistorySuccess,
  getExportResidentialHistoryFailure,
  reseExportResidentialHistorytState,
} from './residentialUnit.action';
import { residentialUnitModel } from './residentialUnit.model';

export interface residentialUnitState {
  residentilaUnit: residentialUnitModel | null;
  createResidentialUnitError: any;
  getResidentialUnitResponse: any;
  createResidentialUnitResponse: any;
  getOccupationHistoryResponse: any;
  getResidentialUnitByIdResponse: any;
  getAllResidentialUnit_Response: any;
  getOccupationHistoryExpotyExcelResponse: any,
  getExportResidentialHistoryResponse: any,
  getResidentialHistoryResponse: any
}

export const initialState: residentialUnitState = {
  residentilaUnit: null,
  getResidentialUnitResponse: null,
  createResidentialUnitResponse: null,
  createResidentialUnitError: null,
  getOccupationHistoryResponse: null,
  getResidentialUnitByIdResponse: null,
  getAllResidentialUnit_Response: null,
  getOccupationHistoryExpotyExcelResponse: null,
  getExportResidentialHistoryResponse: null,
  getResidentialHistoryResponse: null
};

export const residentialUnitReducer = createReducer(
  initialState,


  on(createResidentialUnit, (state) => ({ ...state, error: null })),
  on(addResidentialUnitSuccess, (state, { createResidentialUnitResponse }) => ({ ...state, createResidentialUnitResponse, })),

  on(addResidentialUnitFailure, (state, { createResidentialUnitError }) => ({
    ...state,
    createResidentialUnitError,
  })),

  on(getResidentialUnitlist, (state) => ({ ...state })),
  on(getResidentialUnitSuccess, (state, { getResidentialUnitResponse }) => ({ ...state, getResidentialUnitResponse, })),
  on(getResidentialUnitFailure, (state, { getResidentialUnitError }) => ({ ...state, getResidentialUnitError, })),


  on(getOccupationHistoryExpotyExcellist, (state) => ({ ...state })),
  on(getOccupationHistoryExpotyExcelSuccess, (state, { getOccupationHistoryExpotyExcelResponse }) => ({ ...state, getOccupationHistoryExpotyExcelResponse, })),
  on(getOccupationHistoryExpotyExcelFailure, (state, { getOccupationHistoryExpotyExcelUnitError }) => ({ ...state, getOccupationHistoryExpotyExcelUnitError, })),


  on(getResidentialHistorylist, (state) => ({ ...state })),
  on(getResidentialHistorySuccess, (state, { getResidentialHistoryResponse }) => ({ ...state, getResidentialHistoryResponse, })),
  on(getResidentialHistoryFailure, (state, { getResidentialHistoryUnitError }) => ({ ...state, getResidentialHistoryUnitError, })),


  on(getExportResidentialHistory, (state) => ({ ...state })),
  on(getExportResidentialHistorySuccess, (state, { getExportResidentialHistoryResponse }) => ({ ...state, getExportResidentialHistoryResponse, })),
  on(getExportResidentialHistoryFailure, (state, { getExportResidentialHistoryError }) => ({ ...state, getExportResidentialHistoryError, })),


  //


  //data reset to initial state

  on(reseExportResidentialHistorytState, (state) => ({
    ...state,
    getExportResidentialHistoryResponse: null // Resetting this specific property
  })),




  on(
    getOccupationHistorySuccess,
    (state, { getOccupationHistoryResponse }) => ({
      ...state,
      getOccupationHistoryResponse,
    })
  ),
  on(
    getOccupationHistoryFailure,
    (state, { getOccupationHistoryUnitError }) => ({
      ...state,
      getOccupationHistoryUnitError,
    })
  ),

  on(
    getResidentialUnitByIdSuccess,
    (state, { getResidentialUnitByIdResponse }) => ({
      ...state,
      getResidentialUnitByIdResponse,
    })
  ),
  on(
    getResidentialUnitByIdFailure,
    (state, { getResidentialUnitByIdError }) => ({
      ...state,
      getResidentialUnitByIdError,
    })
  ),
  on(resetResidentialUnitState, () => initialState),
  on(
    getAllResidentialUnitSuccess,
    (state, { getAllResidentialUnit_Response }) => ({
      ...state,
      getAllResidentialUnit_Response,
    })
  ),

  on(resetState, (state) => ({
    ...state,
    getAllResidentialUnit_Response: null // Resetting this specific property
  })),

  on(resetOccupaintHistoryState, (state) => ({
    ...state,
    getOccupationHistoryExpotyExcelResponse: null // Resetting this specific property
  })),



);
