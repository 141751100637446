import { Action, createReducer, on } from '@ngrx/store';
import { gateWayModel } from './gateway.model';
import {
  getGatewayList,
  getGatewayListSucess,
  getGatewayListFailure,
  updateGateWaySuccess,
  createGateway,
  creategatewaySuccess,
  creategatewayFailure,
  deleteGateWaySuccess,
  getAllGatewayList,
  getAllGatewayListSucess,
  getAllGatewayListFailure,
  getGatewayExport,
  getGatewayExportSucess,
  getGatewayExportFailure,
} from './gateway.action';

export interface GatewayState {
  geatwaylist: gateWayModel | null;
  creategatewayError: any;
  creategateway_Response: any;
  getGateway_Response: any;
  deleteGateWayResponse: any;
  getGatewayListSucess: any;
  updateGateWayResponse: any;
  getAllGateway_Response: any;
  getGatewayExportResponse: any
}

export const initialState: GatewayState = {
  geatwaylist: null,
  creategatewayError: null,
  creategateway_Response: null,
  getGateway_Response: null,
  deleteGateWayResponse: null,
  getGatewayListSucess: null,
  updateGateWayResponse: null,
  getAllGateway_Response: null,
  getGatewayExportResponse: null
};

export const gatewayReducer = createReducer(
  initialState,
  on(getGatewayList, (state) => ({ ...state })),
  on(createGateway, (state) => ({ ...state, error: null })),
  on(creategatewaySuccess, (state, { creategateway_Response }) => ({
    ...state,
    creategateway_Response,
  })),
  on(creategatewayFailure, (state, { createGatewayError }) => ({
    ...state,
    createGatewayError,
  })),
  on(getGatewayListSucess, (state, { getGateway_Response }) => ({
    ...state,
    getGateway_Response,
  })),
  on(getGatewayListFailure, (state, { getGatewayError }) => ({
    ...state,
    getGatewayError,
  })),
  on(deleteGateWaySuccess, (state, { deleteGateWayResponse }) => ({
    ...state,
    deleteGateWayResponse,
  })),
  on(updateGateWaySuccess, (state, { updateGateWayResponse }) => ({
    ...state,
    updateGateWayResponse,
  })),
  on(getAllGatewayList, (state) => ({ ...state })),
  on(getAllGatewayListSucess, (state, { getAllGateway_Response }) => ({
    ...state,
    getAllGateway_Response,
  })),
  on(getAllGatewayListFailure, (state, { getAllGatewayError }) => ({
    ...state,
    getAllGatewayError,
  })),


  on(getGatewayExport, (state) => ({ ...state })),
  on(getGatewayExportSucess, (state, { getGatewayExportResponse }) => ({
    ...state,
    getGatewayExportResponse,
  })),
  on(getGatewayExportFailure, (state, { getGatewayExportError }) => ({
    ...state,
    getGatewayExportError,
  }))
);
