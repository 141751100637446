import { createReducer, on } from '@ngrx/store';
import { Faq } from './faq.models';
import { createFaqFailure, createFaqSuccess, delateFaqSuccess, deleteFaqFailure, getFaqFailure, getFaqSuccess, getFaqs } from './faq.actions';

export interface FaqState {
    Faq: Faq | null;
    error: string | null;
    getFaqResponse: any; 
    getFaqError: any;
    createFaqResponse:any;
    createFaqError:any;
    deleteFaqResponse:any;
    deleteFaqError:any;
}

const initialState: FaqState = {
    Faq: null,
    error: null,
    getFaqResponse: null,
    getFaqError: null,
    createFaqResponse:null,
    createFaqError:null,
    deleteFaqResponse:null,
    deleteFaqError:null
};

export const faqReducer = createReducer(
    initialState,

    on(getFaqs, (state) => ({ ...state, error: null,})),
    on(getFaqSuccess, (state, { getFaqResponse }) => ({ ...state, getFaqResponse, error: null, })),
    on(getFaqFailure, (state, { getFaqError }) => ({ ...state, getFaqError })),


    on(createFaqSuccess, (state, { createFaqResponse }) => ({ ...state, createFaqResponse, error: null, })),
    on(createFaqFailure, (state, { createFaqError }) => ({ ...state, createFaqError })),

    
    on(delateFaqSuccess, (state, { deleteFaqResponse }) => ({ ...state, deleteFaqResponse, error: null, })),
    on(deleteFaqFailure, (state, { deleteFaqError }) => ({ ...state, deleteFaqError })),

);
