import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, mergeMap, map, exhaustMap } from 'rxjs/operators';
import {
  createConsumer,
  addConsumerSuccess,
  addConsumerFailure,
  updateConsumer,
  updateConsumerSuccess,
  updateConsumerFailure,
  deleteConsumer,
  deleteConsumerSuccess,
  deleteConsumerFailure,
  getConsumer,
  getConsumerSuccess,
  getConsumerrFail,
  getConsumerStstusId,
  getConsumerStatusSuccess,
  getConsumerSttausrFail,
  getConsumerRechargeHistory,
  getConsumerRechargeHistorySuccess,
  getConsumerRechargeHistoryFail,
  getConsumerFaq,
  getConsumerFaqSuccess,
  getConsumerFaqFail,
  getConsumptionHistory,
  getConsumptionHistorySuccess,
  getConsumptionHistoryFail,
  getConsumerExport,
  getConsumerExportSuccess,
  getConsumerExportrFail,
  getmeterDeatails,
  getmeterDeatailsSuccess,
  getmeterDeatailsFail,
  getmeterControls,
  getmeterControlsSuccess,
  getmeterControlsFail,
  updateMeerStatus,
  updateMeerStatusSuccess,
  updateMeerStatusFail,
  getConsumerMeterExport,
  getConsumerMeterExportSuccess,
  getConsumerMeterExportrFail,
  getConsumerRechargeHistoryExportSuccess,
  getConsumerRechargeHistoryExportFail,
  getConsumerRechargeHistoryExport,
  getConsumptionHistoryExport,
  getConsumptionHistoryExportSuccess,
  getConsumptionHistoryExportFail,
  getDueBillsSuccess,
  getDueBillsFail,
  getDueBills,
  getBillHistory,
  getBillHistorySuccess,
  getBillHistoryFail,
  getBillHistoryExportSuccess,
  getBillHistoryExportFail,
  getBillHistoryExport,
  getBilldeatils,
  getBilldeatilsFail,
  getBilldeatilsSuccess,
  getConsumerMeterOnOffExportrFail,
  getConsumerMeterOnOffExportSuccess,
  getConsumerMeteOnOffExport,
  updateConsumerMeterDetailstSuccess,
  updateConsumerMeterDetailsFail,
  updateConsumerMeterDetails,
  getMeterOnOffControls,
  getMeterOnOffControlsSuccess,
  getMeterOnOffControlsFail,
  getsettlement,
  retrysettlement,
  retrysettlementSuccess,
  retrysettlementFail,
  getsettlementSuccess,
  getsettlementFail,
  getsettlementExportSuccess,
  getsettlementExportFail,
  getsettlementExport,
  checkStatusettlementSuccess,
  checkStatusettlementFail,
  checkStatussettlement,
} from './consumer.action';
import { of } from 'rxjs';
import { ConsumersService } from '../../core/services/consumers.service';
import { Validations } from '../../shared/constants/validations';
import { LoaderService } from '../../core/services/loader.service';
import { SharedService } from '../../shared/shared.service';
import { finalize } from 'rxjs/operators';
import { SweetAlertIcon } from '../../shared/constants/enum';
import { Router } from '@angular/router';

@Injectable()
export class ConsumerEffects {
  ServiceError = Validations.ServiceError;
  getConsumer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getConsumer),
      exhaustMap(
        ({
          ProjectId,
          pageIndex,
          pageSize,
          searchTerm,
          filter,
          orderBy,
          criteria,
        }) => {
          if (!searchTerm) {
            this.loaderService.setLoading(true);
          }
          return this.customersService
            .getConsumers(
              ProjectId,
              pageIndex,
              pageSize,
              searchTerm,
              filter,
              orderBy,
              criteria
            )
            .pipe(
              map((response: any) => {
                this.loaderService.setLoading(false);
                console.log(response);
                if (response && response.statusCode === 200) {
                  return getConsumerSuccess({
                    getConsumersResponse: response.data,
                  });
                } else {
                  return getConsumerrFail({ getConsumersError: response });
                }
              }),
              catchError((error) => {
                console.log(error);
                this.sharedService.showMessageDialog(
                  error.error.message,
                  this.ServiceError.Error,
                  SweetAlertIcon.ERROR
                );
                return of(getConsumerrFail({ getConsumersError: error }));
              }),
              finalize(() => this.loaderService.setLoading(false))
            );
        }
      )
    )
  );

  getConsumerExport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getConsumerExport),
      exhaustMap(({ ProjectId, criteria }) => {
        return this.customersService
          .getConsumersExport(ProjectId, criteria)
          .pipe(
            map((response: any) => {
              this.loaderService.setLoading(false);
              console.log(response);
              if (response && response.statusCode === 200) {
                return getConsumerExportSuccess({
                  getConsumersExportResponse: response.data,
                });
              } else {
                return getConsumerExportrFail({
                  getConsumersExportError: response,
                });
              }
            }),
            catchError((error) => {
              console.log(error);
              this.sharedService.showMessageDialog(
                error.error.message,
                this.ServiceError.Error,
                SweetAlertIcon.ERROR
              );
              return of(
                getConsumerExportrFail({ getConsumersExportError: error })
              );
            }),
            finalize(() => this.loaderService.setLoading(false))
          );
      })
    )
  );
  getBillHistoryExport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getBillHistoryExport),
      exhaustMap(({ ProjectId, criteria }) => {
        return this.customersService
          .getConsumerBillHistoryExport(ProjectId, criteria)
          .pipe(
            map((response: any) => {
              this.loaderService.setLoading(false);
              console.log(response);
              if (response && response.statusCode === 200) {
                return getBillHistoryExportSuccess({
                  getBillHistoryExportResponse: response.data,
                });
              } else {
                return getBillHistoryExportFail({
                  getBillHistoryExportError: response,
                });
              }
            }),
            catchError((error) => {
              console.log(error);
              this.sharedService.showMessageDialog(
                error.error.message,
                this.ServiceError.Error,
                SweetAlertIcon.ERROR
              );
              return of(
                getBillHistoryExportFail({ getBillHistoryExportError: error })
              );
            }),
            finalize(() => this.loaderService.setLoading(false))
          );
      })
    )
  );

  updateConsumerMeterDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateConsumerMeterDetails),
      exhaustMap(({ updaedMeterData }) => {
        return this.customersService
          .updatemeterDeatils(updaedMeterData)
          .pipe(
            map((response: any) => {
              this.loaderService.setLoading(false);
              console.log(response);
              if (response && response.statusCode === 200) {
                return updateConsumerMeterDetailstSuccess({
                  updateConsumerMeterDetailssuccessResponse: response,
                });
              } else {
                return updateConsumerMeterDetailsFail({
                  updateConsumerMeterDetailssuccessError: response,
                });
              }
            }),
            catchError((error) => {
              console.log(error);
              this.sharedService.showMessageDialog(
                error.error.message,
                this.ServiceError.Error,
                SweetAlertIcon.ERROR
              );
              return of(
                updateConsumerMeterDetailsFail({ updateConsumerMeterDetailssuccessError: error })
              );
            }),
            finalize(() => this.loaderService.setLoading(false))
          );
      })
    )
  );

  getConsumerMeterExport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getConsumerMeterExport),
      exhaustMap(({ ProjectId, criteria }) => {
        return this.customersService
          .getConsumersMeterEExport(ProjectId, criteria)
          .pipe(
            map((response: any) => {
              this.loaderService.setLoading(false);
              console.log(response);
              if (response && response.statusCode === 200) {
                return getConsumerMeterExportSuccess({
                  getConsumersMeterExportResponse: response.data,
                });
              } else {
                return getConsumerMeterExportrFail({
                  getConsumersMeterExportError: response,
                });
              }
            }),
            catchError((error) => {
              console.log(error);
              this.sharedService.showMessageDialog(
                error.error.message,
                this.ServiceError.Error,
                SweetAlertIcon.ERROR
              );
              return of(
                getConsumerMeterExportrFail({
                  getConsumersMeterExportError: error,
                })
              );
            }),
            finalize(() => this.loaderService.setLoading(false))
          );
      })
    )
  );

  getConsumerMeteOnOffExport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getConsumerMeteOnOffExport),
      exhaustMap(({ criteria }) => {
        return this.customersService
          .getConsumersMeterOnOffExport(criteria)
          .pipe(
            map((response: any) => {
              this.loaderService.setLoading(false);
              console.log(response);
              if (response && response.statusCode === 200) {
                return getConsumerMeterOnOffExportSuccess({
                  getConsumersMeterOnOffExportResponse: response.data,
                });
              } else {
                return getConsumerMeterOnOffExportrFail({
                  getConsumersMeterOnOffExportError: response,
                });
              }
            }),
            catchError((error) => {
              console.log(error);
              this.sharedService.showMessageDialog(
                error.error.message,
                this.ServiceError.Error,
                SweetAlertIcon.ERROR
              );
              return of(
                getConsumerMeterOnOffExportrFail({
                  getConsumersMeterOnOffExportError: error,
                })
              );
            }),
            finalize(() => this.loaderService.setLoading(false))
          );
      })
    )
  );

  getDueBills$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getDueBills),
      exhaustMap(({ ProjectId }) => {
        return this.customersService.getDueBills(ProjectId).pipe(
          map((response: any) => {
            this.loaderService.setLoading(false);
            console.log(response);
            if (response && response.statusCode === 200) {
              return getDueBillsSuccess({
                getDueBillsResponse: response.data,
              });
            } else {
              return getDueBillsFail({ getDueBillsError: response });
            }
          }),
          catchError((error) => {
            console.log(error);
            this.sharedService.showMessageDialog(
              error.error.message,
              this.ServiceError.Error,
              SweetAlertIcon.ERROR
            );
            return of(getDueBillsFail({ getDueBillsError: error }));
          }),
          finalize(() => this.loaderService.setLoading(false))
        );
      })
    )
  );

  getBilldeatils$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getBilldeatils),
      exhaustMap(({ BillNo }) => {
        return this.customersService.getBillDetaildById(BillNo).pipe(
          map((response: any) => {
            this.loaderService.setLoading(false);
            console.log(response);
            if (response && response.statusCode === 200) {
              return getBilldeatilsSuccess({
                getBilldeatilsResponse: response.data,
              });
            } else {
              return getBilldeatilsFail({ getBilldeatilsError: response });
            }
          }),
          catchError((error) => {
            console.log(error);
            this.sharedService.showMessageDialog(
              error.error.message,
              this.ServiceError.Error,
              SweetAlertIcon.ERROR
            );
            return of(getBilldeatilsFail({ getBilldeatilsError: error }));
          }),
          finalize(() => this.loaderService.setLoading(false))
        );
      })
    )
  );

  getConsumerRechargeHistoryExport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getConsumerRechargeHistoryExport),
      exhaustMap(({ ProjectId, criteria }) => {
        return this.customersService
          .getConsumersRechargeHistoryExport(ProjectId, criteria)
          .pipe(
            map((response: any) => {
              this.loaderService.setLoading(false);
              console.log(response);
              if (response && response.statusCode === 200) {
                return getConsumerRechargeHistoryExportSuccess({
                  getConsumersRechargeHistoryExportResponse: response.data,
                });
              } else {
                return getConsumerRechargeHistoryExportFail({
                  getConsumersRechargeHistoryExportError: response,
                });
              }
            }),
            catchError((error) => {
              console.log(error);
              this.sharedService.showMessageDialog(
                error.error.message,
                this.ServiceError.Error,
                SweetAlertIcon.ERROR
              );
              return of(
                getConsumerRechargeHistoryExportFail({
                  getConsumersRechargeHistoryExportError: error,
                })
              );
            }),
            finalize(() => this.loaderService.setLoading(false))
          );
      })
    )
  );

  getConsumptionHistoryExport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getConsumptionHistoryExport),
      exhaustMap(({ ProjectId, criteria, MeterId }) => {
        return this.customersService
          .getConsumptionRechargeHistoryExport(ProjectId, criteria, MeterId)
          .pipe(
            map((response: any) => {
              this.loaderService.setLoading(false);
              console.log(response);
              if (response && response.statusCode === 200) {
                return getConsumptionHistoryExportSuccess({
                  getconsumptionHistoryExportResponse: response.data,
                });
              } else {
                return getConsumptionHistoryExportFail({
                  getconsumptionHistoryExportError: response,
                });
              }
            }),
            catchError((error) => {
              console.log(error);
              this.sharedService.showMessageDialog(
                error.error.message,
                this.ServiceError.Error,
                SweetAlertIcon.ERROR
              );
              return of(
                getConsumptionHistoryExportFail({
                  getconsumptionHistoryExportError: error,
                })
              );
            }),
            finalize(() => this.loaderService.setLoading(false))
          );
      })
    )
  );

  getConsumerRechargeHistory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getConsumerRechargeHistory),
      exhaustMap(
        ({ ProjectId, pageIndex, pageSize, searchTerm, filter, orderBy }) => {
          // if (!searchTerm) {
          this.loaderService.setLoading(true);
          // }
          return this.customersService
            .getConsumersRechargeHistory(
              ProjectId,
              pageIndex,
              pageSize,
              searchTerm,
              filter,
              orderBy
            )

            .pipe(
              map((response: any) => {
                this.loaderService.setLoading(false);
                console.log(response);
                if (response && response.statusCode === 200) {
                  return getConsumerRechargeHistorySuccess({
                    getConsumersRechargeHistoryResponse: response.data,
                  });
                } else {
                  return getConsumerRechargeHistoryFail({
                    getConsumersRechargeHistoryError: response,
                  });
                }
              }),
              catchError((error) => {
                console.log(error);
                this.sharedService.showMessageDialog(
                  error.error.message,
                  this.ServiceError.Error,
                  SweetAlertIcon.ERROR
                );
                return of(
                  getConsumerRechargeHistoryFail({
                    getConsumersRechargeHistoryError: error,
                  })
                );
              }),
              finalize(() => this.loaderService.setLoading(false))
            );
        }
      )
    )
  );
  getBillHistory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getBillHistory),
      exhaustMap(
        ({ ProjectId, pageIndex, pageSize, searchTerm, filter, orderBy }) => {
          if (!searchTerm) {
            this.loaderService.setLoading(true);
          }
          return this.customersService
            .getConsumerBillHistory(
              ProjectId,
              pageIndex,
              pageSize,
              searchTerm,
              filter,
              orderBy
            )

            .pipe(
              map((response: any) => {
                this.loaderService.setLoading(false);
                console.log(response);
                if (response && response.statusCode === 200) {
                  return getBillHistorySuccess({
                    getBillHistoryResponse: response.data,
                  });
                } else {
                  return getBillHistoryFail({ getBillHistoryError: response });
                }
              }),
              catchError((error) => {
                console.log(error);
                this.sharedService.showMessageDialog(
                  error.error.message,
                  this.ServiceError.Error,
                  SweetAlertIcon.ERROR
                );
                return of(getBillHistoryFail({ getBillHistoryError: error }));
              }),
              finalize(() => this.loaderService.setLoading(false))
            );
        }
      )
    )
  );

  getConsumptionHistory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getConsumptionHistory),
      exhaustMap(
        ({
          ProjectId,
          pageIndex,
          pageSize,
          searchTerm,
          filter,
          orderBy,
          MeterId,
        }) => {
          if (!searchTerm) {
            this.loaderService.setLoading(true);
          }
          return this.customersService
            .getConsumptionRechargeHistory(
              ProjectId,
              pageIndex,
              pageSize,
              searchTerm,
              filter,
              orderBy,
              MeterId
            )

            .pipe(
              map((response: any) => {
                this.loaderService.setLoading(false);
                console.log(response);
                if (response && response.statusCode === 200) {
                  return getConsumptionHistorySuccess({
                    getconsumptionHistoryResponse: response.data,
                  });
                } else {
                  return getConsumptionHistoryFail({
                    getconsumptionHistoryError: response,
                  });
                }
              }),
              catchError((error) => {
                console.log(error);
                this.sharedService.showMessageDialog(
                  error.error.message,
                  this.ServiceError.Error,
                  SweetAlertIcon.ERROR
                );
                return of(
                  getConsumptionHistoryFail({
                    getconsumptionHistoryError: error,
                  })
                );
              }),
              finalize(() => this.loaderService.setLoading(false))
            );
        }
      )
    )
  );

  getmeterDeatails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getmeterDeatails),
      exhaustMap(({ }) => {
        return this.customersService
          .getconsumerMeterDeatils()

          .pipe(
            map((response: any) => {
              this.loaderService.setLoading(false);
              console.log(response);
              if (response && response.statusCode === 200) {
                return getmeterDeatailsSuccess({
                  getmeterDeatailsResponse: response.data,
                });
              } else {
                return getmeterDeatailsFail({
                  getmeterDeatailsError: response,
                });
              }
            }),
            catchError((error) => {
              console.log(error);
              this.sharedService.showMessageDialog(
                error.error.message,
                this.ServiceError.Error,
                SweetAlertIcon.ERROR
              );
              return of(getmeterDeatailsFail({ getmeterDeatailsError: error }));
            }),
            finalize(() => this.loaderService.setLoading(false))
          );
      })
    )
  );

  getmeterControls$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getmeterControls),
      exhaustMap(
        ({ ProjectId, pageIndex, pageSize, searchTerm, filter, orderBy }) => {
          return this.customersService
            .getConsumerMeterControlsUrl(
              ProjectId,
              pageIndex,
              pageSize,
              searchTerm,
              filter,
              orderBy
            )
            .pipe(
              map((response: any) => {
                this.loaderService.setLoading(false);
                console.log(response);
                if (response && response.statusCode === 200) {
                  return getmeterControlsSuccess({
                    getmeterControlsResponse: response.data,
                  });
                } else {
                  return getmeterControlsFail({
                    getmeterControlsError: response,
                  });
                }
              }),
              catchError((error) => {
                console.log(error);
                this.sharedService.showMessageDialog(
                  error.error.message,
                  this.ServiceError.Error,
                  SweetAlertIcon.ERROR
                );
                return of(
                  getmeterControlsFail({ getmeterControlsError: error })
                );
              }),
              finalize(() => this.loaderService.setLoading(false))
            );
        }
      )
    )
  );
  getMeterOnOffControls$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getMeterOnOffControls),
      exhaustMap(
        ({ pageIndex, pageSize, searchTerm, filter, orderBy, FromDate, ToDate }) => {
          return this.customersService
            .getConsumerMeterOnOffControlsUrl(
              pageIndex,
              pageSize,
              searchTerm,
              filter,
              orderBy, FromDate, ToDate
            )
            .pipe(
              map((response: any) => {
                this.loaderService.setLoading(false);
                console.log(response);
                if (response && response.statusCode === 200) {
                  return getMeterOnOffControlsSuccess({
                    getMeterOnOffControlsResponse: response.data,
                  });
                } else {
                  return getMeterOnOffControlsFail({
                    getMeterOnOffControlsError: response,
                  });
                }
              }),
              catchError((error) => {
                console.log(error);
                this.sharedService.showMessageDialog(
                  error.error.message,
                  this.ServiceError.Error,
                  SweetAlertIcon.ERROR
                );
                return of(
                  getmeterControlsFail({ getmeterControlsError: error })
                );
              }),
              finalize(() => this.loaderService.setLoading(false))
            );
        }
      )
    )
  );

  getConsumerFaq$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getConsumerFaq),
      exhaustMap(({ }) => {
        return this.customersService.getConsumerFaqs().pipe(
          map((response: any) => {
            this.loaderService.setLoading(false);
            console.log(response);
            if (response && response.statusCode === 200) {
              return getConsumerFaqSuccess({
                getConsumersFaqResponse: response.data,
              });
            } else {
              return getConsumerFaqFail({ getConsumersFaqError: response });
            }
          }),
          catchError((error) => {
            console.log(error);
            this.sharedService.showMessageDialog(
              error.error.message,
              this.ServiceError.Error,
              SweetAlertIcon.ERROR
            );
            return of(getConsumerFaqFail({ getConsumersFaqError: error }));
          }),
          finalize(() => this.loaderService.setLoading(false))
        );
      })
    )
  );

  createConsumer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createConsumer),
      exhaustMap((consumerData) => {
        console.log(consumerData);
        this.loaderService.setLoading(true);
        return this.customersService
          .createConsumer(consumerData.consumerData)
          .pipe(
            map((response: any) => {
              console.log(response);
              this.loaderService.setLoading(false);
              if (response && response.statusCode === 201) {
                this.sharedService.showMessageDialog(
                  'Success',
                  response.message,
                  SweetAlertIcon.SUCCESS
                );
                this.router.navigate(['/consumers']);
                return addConsumerSuccess({ createConsumerResponse: response });
              } else {
                this.sharedService.showMessageDialog(
                  'Failure',
                  response.message,
                  SweetAlertIcon.ERROR
                );
                return addConsumerFailure({ createConsumerError: response });
              }
            }),
            catchError((error) => {
              console.log(error);

              this.sharedService.showMessageDialog(
                error.error.message,
                this.ServiceError.Error,
                SweetAlertIcon.ERROR
              );
              return of(addConsumerFailure({ createConsumerError: error }));
            }),
            finalize(() => this.loaderService.setLoading(false))
          );
      })
    )
  );

  updateMeerStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateMeerStatus),
      exhaustMap(({ meterId, meterStatus }) => {
        console.log(meterId, meterStatus);
        this.loaderService.setLoading(true);
        return this.customersService
          .ConsumerupdateMeterStatusUrl(meterId, meterStatus)
          .pipe(
            map((response: any) => {
              console.log(response);
              this.loaderService.setLoading(false);
              if (response && response.statusCode === 201) {
                this.sharedService.showMessageDialog(
                  'Success',
                  response.message,
                  SweetAlertIcon.SUCCESS
                );
                return updateMeerStatusSuccess({
                  updateMeerStatusResponse: response,
                });
              } else {
                this.sharedService.showMessageDialog(
                  'Failure',
                  response.message,
                  SweetAlertIcon.ERROR
                );
                return updateMeerStatusFail({
                  updateMeerStatusError: response,
                });
              }
            }),
            catchError((error) => {
              console.log(error);

              this.sharedService.showMessageDialog(
                error.error.message,
                this.ServiceError.Error,
                SweetAlertIcon.ERROR
              );
              return of(addConsumerFailure({ createConsumerError: error }));
            }),
            finalize(() => this.loaderService.setLoading(false))
          );
      })
    )
  );

  updateConsumer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateConsumer),
      exhaustMap((updatedData) => {
        console.log(updatedData, '-----');
        this.loaderService.setLoading(true);
        return this.customersService
          .updateConsumer(updatedData.updatedData, updatedData.id)
          .pipe(
            map((response: any) => {
              this.loaderService.setLoading(false);
              if (response && response.statusCode === 200) {
                this.sharedService.showMessageDialog(
                  'Success',
                  response.message,
                  SweetAlertIcon.SUCCESS
                );
                this.router.navigate(['/consumers']);
                return updateConsumerSuccess({
                  updateConsumerResponse: response,
                });
                // return getuserlist({ pageIndex: 1, pageSize: 10, searchTerm: '', criteria: "" })
              } else {
                this.sharedService.showMessageDialog(
                  'Failure',
                  response.message,
                  SweetAlertIcon.ERROR
                );
                return updateConsumerFailure({ updateConsumerError: response });
              }
            }),
            catchError((error) => {
              console.log(error);
              this.sharedService.showMessageDialog(
                error.error.message,
                this.ServiceError.Error,
                SweetAlertIcon.ERROR
              );
              return of(updateConsumerFailure({ updateConsumerError: error }));
            }),
            finalize(() => this.loaderService.setLoading(false))
          );
      })
    )
  );

  deleteConsumer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteConsumer),
      exhaustMap((userData, id) => {
        console.log(userData, '-----');
        this.loaderService.setLoading(true);
        return this.customersService.deleteConsumer(userData.id).pipe(
          map((response: any) => {
            this.loaderService.setLoading(false);
            if (response && response.statusCode === 200) {
              this.sharedService.showMessageDialog(
                'Success',
                response.message,
                SweetAlertIcon.SUCCESS
              );
              return deleteConsumerSuccess({
                deleteConsumerResponse: response,
              });
              // return getConsumer({
              //   ProjectId: userData.projectId,
              //   pageIndex: 1,
              //   pageSize: 10,
              //   searchTerm: '',
              //   criteria: '',
              // });
            } else {
              this.sharedService.showMessageDialog(
                'Failure',
                response.message,
                SweetAlertIcon.ERROR
              );
              return deleteConsumerFailure({ deleteConsumerError: response });
            }
          }),
          catchError((error) => {
            console.log(error);
            this.sharedService.showMessageDialog(
              error.error.message,
              this.ServiceError.Error,
              SweetAlertIcon.ERROR
            );
            return of(deleteConsumerFailure({ deleteConsumerError: error }));
          }),
          finalize(() => this.loaderService.setLoading(false))
        );
      })
    )
  );

  getConsumerStstusId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getConsumerStstusId),
      exhaustMap(({ id, status }) => {
        return this.customersService.getConsumerStatusId(id, status).pipe(
          map((response: any) => {
            this.loaderService.setLoading(false);
            console.log(response);
            if (response && response.statusCode === 200) {
              this.sharedService.showMessageDialog(
                'Success',
                response.message,
                SweetAlertIcon.SUCCESS
              );

              return getConsumerStatusSuccess({
                getConsumerStatusResponse: response.data,
              });
            } else {
              return getConsumerSttausrFail({
                getConsumerStatusError: response,
              });
            }
          }),
          catchError((error) => {
            console.log(error);
            this.sharedService.showMessageDialog(
              error.error.message,
              this.ServiceError.Error,
              SweetAlertIcon.ERROR
            );
            return of(
              getConsumerSttausrFail({ getConsumerStatusError: error })
            );
          }),
          finalize(() => this.loaderService.setLoading(false))
        );
      })
    )
  );



  getsettlement$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getsettlement),
      exhaustMap(
        ({ pageIndex, pageSize, searchTerm, filter, orderBy }) => {
          return this.customersService
            .getSettlementdeatils(
              pageIndex,
              pageSize,
              searchTerm,
              filter,
              orderBy
            )
            .pipe(
              map((response: any) => {
                this.loaderService.setLoading(false);
                console.log(response);
                if (response && response.statusCode === 200) {
                  return getsettlementSuccess({
                    getsettlementResponse: response.data,
                  });
                } else {
                  return getsettlementFail({
                    getsettlementError: response,
                  });
                }
              }),
              catchError((error) => {
                console.log(error);
                this.sharedService.showMessageDialog(
                  error.error.message,
                  this.ServiceError.Error,
                  SweetAlertIcon.ERROR
                );
                return of(
                  getsettlementFail({ getsettlementError: error })
                );
              }),
              finalize(() => this.loaderService.setLoading(false))
            );
        }
      )
    )
  );

  retrysettlement$ = createEffect(() =>
    this.actions$.pipe(
      ofType(retrysettlement),
      exhaustMap((settlementData) => {
        console.log(settlementData);
        this.loaderService.setLoading(true);
        setTimeout(() => { });
        return this.customersService.addSettlment(settlementData.settlementData).pipe(
          map((response: any) => {
            console.log(response);
            //  this.loaderService.setLoading(false);
            if (response && response.statusCode === 201) {
              this.sharedService.showMessageDialog(
                'Success',
                response.message,
                SweetAlertIcon.SUCCESS
              );
              return getsettlement({
                pageIndex: 1,
                pageSize: 10,
                searchTerm: '',
              });
              // return retrysettlementSuccess({ retrysettlementResponse: response });
            } else {
              this.sharedService.showMessageDialog(
                'Failure',
                response.message,
                SweetAlertIcon.ERROR
              );
              return retrysettlementFail({ retrysettlementError: response });
            }
          }),
          catchError((error) => {
            console.log(error);
            this.loaderService.setLoading(false);
            this.sharedService.showMessageDialog(
              error.error.message,
              '',
              SweetAlertIcon.ERROR
            );
            return of(retrysettlementFail({ retrysettlementError: error }));
          }),
          finalize(() => this.loaderService.setLoading(false))
        );
      })
    )
  );
  getsettlementExport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getsettlementExport),
      exhaustMap(
        ({ pageIndex, pageSize, searchTerm, filter, orderBy }) => {
          return this.customersService
            .getSettlementdeatils(
              pageIndex,
              pageSize,
              searchTerm,
              filter,
              orderBy
            )
            .pipe(
              map((response: any) => {
                this.loaderService.setLoading(false);
                console.log(response);
                if (response && response.statusCode === 200) {
                  return getsettlementExportSuccess({
                    getsettlementExportResponse: response.data,
                  });
                } else {
                  return getsettlementExportFail({
                    getsettlementExportError: response,
                  });
                }
              }),
              catchError((error) => {
                console.log(error);
                this.sharedService.showMessageDialog(
                  error.error.message,
                  this.ServiceError.Error,
                  SweetAlertIcon.ERROR
                );
                return of(
                  getsettlementExportFail({ getsettlementExportError: error })
                );
              }),
              finalize(() => this.loaderService.setLoading(false))
            );
        }
      )
    )
  );

  checkStatussettlement$ = createEffect(() =>
    this.actions$.pipe(
      ofType(checkStatussettlement),
      exhaustMap(
        ({ transferId }) => {
          console.log(transferId)
          return this.customersService.checkStatusSettlment(transferId)
            .pipe(
              map((response: any) => {
                this.loaderService.setLoading(false);
                console.log(response);
                if (response && response.statusCode === 200) {
                  this.sharedService.showMessageDialog(
                    'Success',
                    response.message,
                    SweetAlertIcon.SUCCESS
                  );
                  return checkStatusettlementSuccess({
                    checkStatusettlementResponse: response.data,
                  });
                } else {
                  return checkStatusettlementFail({
                    checkStatusettlementError: response,
                  });
                }
              }),
              catchError((error) => {
                console.log(error);
                this.sharedService.showMessageDialog(
                  error.error.message,
                  this.ServiceError.Error,
                  SweetAlertIcon.ERROR
                );
                return of(
                  checkStatusettlementFail({ checkStatusettlementError: error })
                );
              }),
              finalize(() => this.loaderService.setLoading(false))
            );
        }
      )
    )
  );





  constructor(
    private actions$: Actions,
    private customersService: ConsumersService,
    private loaderService: LoaderService,
    private sharedService: SharedService,
    private router: Router
  ) { }
}
